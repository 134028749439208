import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNaver = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.304 3H7.696A4.696 4.696 0 0 0 3 7.696v8.608A4.696 4.696 0 0 0 7.696 21h8.608A4.696 4.696 0 0 0 21 16.304V7.696A4.696 4.696 0 0 0 16.304 3Z"
      fill="#58C467"
    />
    <path
      d="M10.875 8.25H8.25v7.875h2.625V12.75l2.625 3.375h2.625V8.25H13.5v3.375L10.875 8.25Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIcoNaver;
