import { NavigationTypes } from '@src/types/types';
import {
  DARK_THEME,
  DATE_FORMAT_YYYYMMDD,
  LIGHT_THEME,
} from '@src/constants/constant';
import moment from 'moment';
import FormData from 'form-data';
import { OpenAPIConfig } from '@src/api';
import { ApiRequestOptions } from '@src/api/core/ApiRequestOptions';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name: string) {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(
    navTree: NavigationTypes | NavigationTypes[],
    path: string,
  ): NavigationTypes {
    function instanceOfStudent(
      data: NavigationTypes | NavigationTypes[],
    ): data is NavigationTypes {
      if (!data || Array.isArray(data)) {
        return false;
      }
      return 'path' in data;
    }

    if (instanceOfStudent(navTree) && navTree.path === path) {
      return navTree;
    }

    let route!: NavigationTypes;
    if (Array.isArray(navTree)) {
      const _navTree = navTree as NavigationTypes[];
      for (const p in _navTree) {
        const index = parseInt(p);
        if (instanceOfStudent(_navTree[index])) {
          route = this.getRouteInfo(_navTree[index], path);
          if (route) {
            return route;
          }
        }
      }
    } else {
      const _navTree = navTree as NavigationTypes;
      if (_navTree.path === path) {
        route = _navTree;
      } else {
        route = this.getRouteInfo(_navTree.submenu, path);
        if (route) {
          return route;
        }
      }
    }

    return route;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex: string) {
    if (!hex) {
      return DARK_THEME;
    }
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    function hexToR(h: string) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h: string) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h: string) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    function cutHex(h: string) {
      return h.charAt(0) === '#' ? h.substring(1, 7) : h;
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return DARK_THEME;
    } else {
      return LIGHT_THEME;
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color: string, percent: number) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = (R * (100 + percent)) / 100;
    G = (G * (100 + percent)) / 100;
    B = (B * (100 + percent)) / 100;
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Convert RGBA to HEX
   * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
   * @return {String} HEX color
   */
  static rgbaToHex(rgba: string) {
    const trim = (str: string) => str.replace(/^\s+|\s+$/gm, '');
    const inParts = rgba.substring(rgba.indexOf('(')).split(','),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(
        parseFloat(
          trim(inParts[3].substring(0, inParts[3].length - 1)),
        ).toFixed(2),
      );

    //TODO: 체크
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2),
    ];

    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    });
    return `#${outParts.join('')}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number: number, positive: any, negative: any) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a: any, b: any, key: string) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key];
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list: Array<any>, key: string, value: any) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list: Array<any>, key: string, value: any) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list: Array<any>, input: number | string) {
    const searchText = (item: any) => {
      for (const key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens: any) {
    const breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  static isMobile() {
    return !Utils.getBreakPoint(useBreakpoint()).includes('lg');
  }

  static isToday(date: string) {
    const now = moment().endOf('day').format(DATE_FORMAT_YYYYMMDD);
    const target = moment(date).format(DATE_FORMAT_YYYYMMDD);
    return now === target;
  }

  static isDefined<T>(
    value: T | null | undefined,
  ): value is Exclude<T, null | undefined> {
    return value !== undefined && value !== null;
  }

  static isString(value: any): value is string {
    return typeof value === 'string';
  }

  static isStringWithValue = (value: any): value is string => {
    return Utils.isString(value) && value !== '';
  };

  static isBlob = (value: any): value is Blob => {
    return (
      typeof value === 'object' &&
      typeof value.type === 'string' &&
      typeof value.stream === 'function' &&
      typeof value.arrayBuffer === 'function' &&
      typeof value.constructor === 'function' &&
      typeof value.constructor.name === 'string' &&
      /^(Blob|File)$/.test(value.constructor.name) &&
      /^(Blob|File)$/.test(value[Symbol.toStringTag])
    );
  };

  static isFormData = (value: any): value is FormData => {
    return value instanceof FormData;
  };

  static isSuccess = (status: number): boolean => {
    return status >= 200 && status < 300;
  };

  static base64 = (str: string): string => {
    try {
      return btoa(str);
    } catch (err) {
      return Buffer.from(str).toString('base64');
    }
  };

  static getQueryString(params: Record<string, any>): string {
    const qs: string[] = [];

    const append = (key: string, value: any) => {
      qs.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
      );
    };

    const process = (key: string, value: any) => {
      if (Utils.isDefined(value)) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            process(key, v);
          });
        } else if (typeof value === 'object') {
          Object.entries(value).forEach(([k, v]) => {
            process(`${key}[${k}]`, v);
          });
        } else {
          append(key, value);
        }
      }
    };

    Object.entries(params).forEach(([key, value]) => {
      process(key, value);
    });

    if (qs.length > 0) {
      return `?${qs.join('&')}`;
    }

    return '';
  }

  static getUrl(config: OpenAPIConfig, options: ApiRequestOptions): string {
    const encoder = config.ENCODE_PATH || encodeURI;

    const path = options.url
      .replace('{api-version}', config.VERSION)
      .replace(/{(.*?)}/g, (substring: string, group: string) => {
        if (options.path?.hasOwnProperty(group)) {
          return encoder(String(options.path[group]));
        }
        return substring;
      });

    const url = `${config.BASE}${path}`;
    if (options.query) {
      return `${url}${Utils.getQueryString(options.query)}`;
    }
    return url;
  }

  static getFormData(options: ApiRequestOptions): FormData | undefined {
    if (options.formData) {
      const formData = new FormData();

      const process = (key: string, value: any) => {
        if (Utils.isString(value) || Utils.isBlob(value)) {
          formData.append(key, value);
        } else {
          formData.append(key, JSON.stringify(value));
        }
      };

      Object.entries(options.formData)
        .filter(([_, value]) => Utils.isDefined(value))
        .forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => process(key, v));
          } else {
            process(key, value);
          }
        });

      return formData;
    }
    return undefined;
  }

  static getRandomString(length: number): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

export default Utils;
