import React, { ReactElement, useEffect, useRef } from 'react';
import { Card } from 'antd';
import ApexChart from 'react-apexcharts';
import ReactResizeDetector from 'react-resize-detector';
import {
  apexAreaChartDefaultOption,
  apexBarChartDefaultOption,
  apexLineChartDefaultOption,
} from '@src/constants/chartConstant';
import utils from '@src/utils/utils';

const getChartTypeDefaultOption = (type: string) => {
  switch (type) {
    case 'line':
      return apexLineChartDefaultOption;
    case 'bar':
      return apexBarChartDefaultOption;
    case 'area':
      return apexAreaChartDefaultOption;
    default:
      return apexLineChartDefaultOption;
  }
};

const ChartWidget = ({
  series = [],
  width,
  height = 300,
  xAxis,
  customOptions,
  type = 'line',
}: ChartWidgetProps) => {
  let options = JSON.parse(JSON.stringify(getChartTypeDefaultOption(type)));

  options.xaxis.categories = xAxis;
  if (customOptions) {
    options = { ...options, ...customOptions };
  }

  return (
    <ReactResizeDetector
      onResize={() => {
        setTimeout(() => {
          //
        }, 600);
      }}
    >
      <div className="chartRef">
        <ApexChart
          options={options}
          type={type}
          series={series}
          width={width}
          height={height}
        />
      </div>
    </ReactResizeDetector>
  );
};

interface ChartWidgetProps {
  series: any[];
  xAxis?: any[];
  customOptions?: object;
  width?: string | number;
  height?: string | number;
  type?:
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar';
  bodyClass?: string;
}

export default ChartWidget;
