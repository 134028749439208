import React from 'react';
import { APP_PREFIX_PATH } from '@src/constants/constant';
import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import Utils from '@src/utils/utils';
import { useChannelTalk } from '@hooks/channelTalk/useChannelTalk';
import { useMainInfo } from '@hooks/main';
import { IcoAnalytics, IcoMd, IcoNews } from '@src/assets/svg';

export const Footer = () => {
  const isMobile = Utils.isMobile();

  if (!isMobile) {
    return null;
  }
  return (
    <footer className={`footer`}>
      <MobileFooter />
    </footer>
  );
};

const MobileFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const channelTalk = useChannelTalk();
  const [mainInfo, _] = useMainInfo();

  const isVisibleChannel = mainInfo.isVisibleChannelTalk;

  const isActive = (path: string) => {
    return location.pathname.includes(APP_PREFIX_PATH + path);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const path = e.currentTarget.dataset['path'];
    console.log(path);

    if (!path) {
      return;
    }

    navigate(path);
  };

  const handleOnClickChannelTalk = () => {
    if (isVisibleChannel) {
      channelTalk.hide();
    } else {
      channelTalk.show();
    }
  };

  return (
    <div className={'wrap mobile'}>
      <div className={'item-wrap'}>
        <Button
          type={'text'}
          className={`item ${
            isVisibleChannel === false && isActive('/analysis') ? 'active' : ''
          }`}
          onClick={handleOnClick}
          data-path={`${APP_PREFIX_PATH}/analysis/main`}
        >
          <IcoAnalytics className={'footer-icon'} />
          <div className={'footer-text'}>분석</div>
        </Button>

        <Button
          type={'text'}
          className={`item ${isVisibleChannel === true ? 'active' : ''}`}
          onClick={handleOnClickChannelTalk}
        >
          <IcoMd className={'footer-icon'} />
          <div className={'footer-text'}>무료상담</div>
        </Button>

        <Button
          type={'text'}
          className={`item ${
            isVisibleChannel === false && isActive('/my-news') ? 'active' : ''
          }`}
          onClick={handleOnClick}
          data-path={`${APP_PREFIX_PATH}/my-news/keyword-news`}
        >
          <IcoNews className={'footer-icon'} />
          <div className={'footer-text'}>내 소식</div>
        </Button>
      </div>
    </div>
  );
};
