import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoWalletMoney = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.367 28.803v3.468c0 2.938-2.733 5.313-6.099 5.313-3.365 0-6.115-2.375-6.115-5.313v-3.468c0 2.938 2.733 5.023 6.115 5.023 3.366 0 6.1-2.102 6.1-5.023Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.365 24.105c0 .854-.24 1.64-.65 2.323-1.007 1.657-3.075 2.7-5.466 2.7-2.392 0-4.459-1.06-5.467-2.7a4.482 4.482 0 0 1-.649-2.323c0-1.47.683-2.785 1.777-3.742 1.11-.973 2.63-1.554 4.322-1.554 1.691 0 3.211.598 4.322 1.554 1.127.94 1.81 2.272 1.81 3.742Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.367 24.105v4.697c0 2.939-2.733 5.023-6.099 5.023-3.365 0-6.115-2.101-6.115-5.023v-4.697c0-2.939 2.733-5.313 6.115-5.313 1.692 0 3.212.598 4.323 1.554 1.093.957 1.776 2.29 1.776 3.759ZM37.583 18.74v3.52c0 .94-.751 1.708-1.708 1.742h-3.348c-1.845 0-3.537-1.35-3.69-3.194a3.431 3.431 0 0 1 1.025-2.785 3.405 3.405 0 0 1 2.46-1.025h3.553a1.754 1.754 0 0 1 1.708 1.742Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.417 17.938V14.52c0-4.647 2.801-7.893 7.158-8.44a9.091 9.091 0 0 1 1.383-.102h15.375c.445 0 .872.017 1.282.086 4.407.512 7.26 3.775 7.26 8.456v2.477h-3.553c-.957 0-1.828.376-2.46 1.025a3.43 3.43 0 0 0-1.025 2.785c.154 1.845 1.845 3.194 3.69 3.194h3.348v2.477c0 5.125-3.417 8.542-8.542 8.542h-4.27"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoWalletMoney;
