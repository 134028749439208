import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoKeyword = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.696 3h8.608A4.696 4.696 0 0 1 21 7.696v8.608A4.696 4.696 0 0 1 16.304 21H7.696A4.696 4.696 0 0 1 3 16.304V7.696A4.696 4.696 0 0 1 7.696 3Zm2.635 4H8v10h2.331v-2.617l.816-1.188L13.203 17H16l-3.117-5.546L15.933 7h-2.737l-2.742 4.095h-.123V7Z"
      fill="#24C185"
    />
  </svg>
);
export default SvgIcoKeyword;
