import React from 'react';
import { useAtomValue } from 'jotai';
import {
  APP_NAME,
  LIGHT_THEME,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from '@src/constants/constant';
import utils from '@src/utils/utils';

import { themeNavCollapsedAtom } from '@src/store/theme';
import { IcoLogo, IcoLogoWhite } from '@src/assets/svg';
import Icon from '@ant-design/icons';

interface LogoProps {
  logoType?: string;
  mobileLogo: boolean;
}

const getLogo = (navCollapsed: boolean, logoType?: string) => {
  if (logoType === LIGHT_THEME) {
    if (navCollapsed) {
      return '/img/logo-sm-white.png';
    }
    return '/img/logo-white.png';
  }

  if (navCollapsed) {
    return '/img/logo-sm.png';
  }
  return '/img/logo.png';
};

export const Logo = (props: LogoProps) => {
  const { logoType } = props;

  return (
    <div className={'logo'}>
      {logoType === LIGHT_THEME ? (
        <IcoLogo width={111} height={30} />
      ) : (
        <IcoLogoWhite width={111} height={30} />
      )}
    </div>
  );
};
