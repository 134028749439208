import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoInsta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.304 3H7.696A4.696 4.696 0 0 0 3 7.696v8.608A4.696 4.696 0 0 0 7.696 21h8.608A4.696 4.696 0 0 0 21 16.304V7.696A4.696 4.696 0 0 0 16.304 3Z"
      fill="#EF33A3"
    />
    <path d="M17 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 12a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm5-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIcoInsta;
