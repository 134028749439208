import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoSetting = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.3334 7.96578V3.66661H14.0342L11.0001 0.632446L7.9659 3.66661H3.66674V7.96578L0.632568 10.9999L3.66674 14.0341V18.3333H7.9659L11.0001 21.3674L14.0342 18.3333H18.3334V14.0341L21.3676 10.9999L18.3334 7.96578ZM16.5001 13.2733V16.4999H13.2734L11.0001 18.7733L8.72674 16.4999H5.50007V13.2733L3.22674 10.9999L5.50007 8.72661V5.49995H8.72674L11.0001 3.22661L13.2734 5.49995H16.5001V8.72661L18.7734 10.9999L16.5001 13.2733ZM11.0001 5.49995C7.9659 5.49995 5.50007 7.96578 5.50007 10.9999C5.50007 14.0341 7.9659 16.4999 11.0001 16.4999C14.0342 16.4999 16.5001 14.0341 16.5001 10.9999C16.5001 7.96578 14.0342 5.49995 11.0001 5.49995ZM11.0001 14.6666C8.97424 14.6666 7.3334 13.0258 7.3334 10.9999C7.3334 8.97411 8.97424 7.33328 11.0001 7.33328C13.0259 7.33328 14.6667 8.97411 14.6667 10.9999C14.6667 13.0258 13.0259 14.6666 11.0001 14.6666Z" />
    </svg>
  );
};
export default SvgIcoSetting;
