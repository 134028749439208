import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoMap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m15.375 2.25-.12.022-4.005 1.553-4.5-1.575-4.23 1.425a.378.378 0 0 0-.27.36v11.34c0 .21.165.375.375.375l.12-.023 4.005-1.552 4.5 1.575 4.23-1.425a.378.378 0 0 0 .27-.36V2.625a.371.371 0 0 0-.375-.375ZM7.5 4.103l3 1.05v8.745l-3-1.05V4.101Zm-3.75.742L6 4.088v8.774l-2.25.87V4.845Zm10.5 8.31-2.25.758V5.144l2.25-.87v8.88Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoMap;
