import React, { ReactElement } from 'react';
import { Card } from 'antd';
import utils from '@src/utils/utils';

interface StatisticWidgetPros {
  title?: string | ReactElement;
  value?: string;
  subtitle?: string;
  rate?: string | number;
  status?: 'LOWER' | 'MAINTENANCE' | 'UPPER';
  prefix?: ReactElement;
  tailText?: string;
  tailItem?: ReactElement | ReactElement[];
  className?: string;
}

const StatisticWidget = ({
  title,
  value,
  status = 'MAINTENANCE',
  rate,
  subtitle,
  prefix,
  tailText,
  className,
  tailItem,
}: StatisticWidgetPros) => {
  return (
    <Card
      bordered={false}
      className={`statistic-widget ${className ? className : ''}`}
    >
      {title && <div className={'title'}>{title}</div>}
      <div className={'point'}>
        {value} {tailText}
      </div>
      <div className={status !== 'MAINTENANCE' ? 'desc' : ''}>
        {subtitle}
        {status === 'UPPER' && '+'}
        {status === 'MAINTENANCE' && ' '}
        {rate}
      </div>
      {prefix ? <div className="mb-0">{prefix}</div> : null}
      {tailItem && tailItem}
    </Card>
  );
};

export default StatisticWidget;
