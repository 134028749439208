import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { NavigationTypes } from '@src/types/types';
import navigationConfig, {
  bottomNavigationConfig,
} from '@src/layouts/navigation/navigation';

import 'antd/lib/breadcrumb/style/index.css';

const breadcrumbData: { [key: string]: string } = {
  '/app': 'home',
};

[...navigationConfig, ...bottomNavigationConfig].forEach(
  (elm: NavigationTypes) => {
    const assignBreadcrumb = (obj: NavigationTypes) =>
      (breadcrumbData[obj.path] = obj.title);
    assignBreadcrumb(elm);
    if (elm.submenu) {
      elm.submenu.forEach((elm) => {
        assignBreadcrumb(elm);
        if (elm.submenu) {
          elm.submenu.forEach((elm) => {
            assignBreadcrumb(elm);
          });
        }
      });
    }
  },
);

export const AppBreadcrumb = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const buildBreadcrumb = pathSnippets.map((str, index) => {
    if (str === 'my-news' || str === 'analysis') {
      return;
    }

    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbData[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  return <Breadcrumb>{buildBreadcrumb}</Breadcrumb>;
};
