import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Loading } from '@components/shared';
import AsyncBoundary from '@components/shared/AsyncBoundary';
import { FallbackProps } from 'react-error-boundary';
import PageError from '@components/shared/PageError';
import Survey from '@views/main/Survey';
import Main from '@views/main/Main';
import Error404 from '@views/errors/Error404';
import Error500 from '@views/errors/Error500';
import AboutAs from '@views/main/AboutAs';
import { APP_HOST } from '@src/constants/constant';
import Login from '@views/main/Login';

export const MainRoutes = () => {
  console.log('MainRoutes');

  return (
    <AsyncBoundary
      ErrorFallback={(fallbackProps: FallbackProps) => (
        <PageError fallbackProps={fallbackProps} />
      )}
      SuspenseFallback={<Loading cover="content" />}
    >
      <Routes>
        <Route path={`auth/oauth/callback`} element={<Login />} />
        {/* 임시로 로컬에서는 Main 화면으로 이동 */}
        <Route
          path="main"
          element={
            APP_HOST === 'LOCAL' || APP_HOST === 'DEV' ? <Main /> : <AboutAs />
          }
        />
        <Route path="main/survey" element={<Survey />} />
        <Route path={`error-1`} element={<Error404 />} />
        <Route path={`error-2`} element={<Error500 />} />
        <Route path={`*`} element={<Error404 />} />
      </Routes>
    </AsyncBoundary>
  );
};

export default MainRoutes;
