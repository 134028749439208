import React, { ReactElement } from 'react';
import { useKakaoLogin } from '@hooks/auth';

const kakaoLoginImg = '/img/kakao_login_medium_wide.png';

interface LoginFormProps {
  showLoading?: boolean;
  extra?: string | ReactElement;
  loading?: boolean;
  showMessage?: string;
  message?: string;
}

const KakaoLogin = (props: LoginFormProps) => {
  const kakaoLogin = useKakaoLogin();

  const handleOnClickKakaoLogin = async (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();

    kakaoLogin();
  };

  return (
    <div className="d-flex justify-content-center">
      <a href="@!" onClick={handleOnClickKakaoLogin}>
        <img src={kakaoLoginImg} alt="" />
      </a>
    </div>
  );
};

export default KakaoLogin;
