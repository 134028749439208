import { LIGHT_THEME, LOCAL_STORAGE_THEME_KEY } from '@src/constants/constant';
import { AppConfigThemeTypes } from '@src/types/types';

import { atomWithImmer } from 'jotai/immer';
import { atom } from 'jotai';

//atom

const localData = window.localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
const initialValue = localData
  ? JSON.parse(localData)
  : {
      navCollapsed: true,
      mobileNav: false,
      currentTheme: LIGHT_THEME,
    };
export const themeAtom = atomWithImmer<AppConfigThemeTypes>(initialValue);

themeAtom.onMount = (set) => {
  const localData = window.localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
  if (!localData || localData === '{}') {
    return;
  }

  set(JSON.parse(localData));
};

//action
export const themeNavCollapsedAtom = atom(
  (get) => get(themeAtom).navCollapsed,
  (get, set, value: boolean) => {
    set(themeAtom, (data) => {
      data.navCollapsed = value;
    });

    const newData = get(themeAtom);
    updateLocalStorage(newData);
  },
);

export const themeMobileNavAtom = atom(
  (get) => get(themeAtom).mobileNav,
  (get, set, value: boolean) => {
    set(themeAtom, (data) => {
      data.mobileNav = value;
    });

    const newData = get(themeAtom);
    updateLocalStorage(newData);
  },
);

export const themeCurrentThemeAtom = atom(
  (get) => get(themeAtom).currentTheme,
  (get, set, value: string) => {
    set(themeAtom, (data) => {
      data.currentTheme = value;
    });

    const newData = get(themeAtom);
    updateLocalStorage(newData);
  },
);

const updateLocalStorage = (data: AppConfigThemeTypes) => {
  window.localStorage.setItem(LOCAL_STORAGE_THEME_KEY, JSON.stringify(data));
};
