import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoLogoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 355 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M69.84 40.96h-8V16H48.32v68.32h13.52V54.08h8V40.96ZM0 81.12h39.76V17.6H0v63.52Zm13.52-50.96h12.72v38.4H13.52v-38.4ZM142.633 16h-12.64v48.24h12.64V16Zm-37.92 27.84h8.08v10.32h12.48V16.32h-12.48v15.2h-5.92V16.8h-31.44V28h18.96V32.64h-18.96V43.6h16.08c-2.8 4.08-7.92 5.92-16.08 6v11.36c13.92 0 24.8-5.84 29.28-17.12Zm41.04 29.36-27.6-8.8a26.74 26.74 0 0 0 2.4-6.08l-13.52-2.64c-2.8 10.08-14.32 16-27.92 16v12.64c11.92 0 23.36-3.44 31.44-10.4l30.8 11.2 4.4-11.92ZM223.746 28.48h-8V16h-13.52v40.64h13.52V41.6h8V28.48Zm-24.72 23.2V40.64c-3.6 1.44-9.6 2.24-15.76 2.24h-16.56V28h28.4V16.8h-42v37.28h29.6c6.4 0 12.8-.88 16.32-2.4Zm-43.92 18.88h46.88v14.56h13.76V59.04h-60.64v11.52ZM281.419 16v25.28h-9.76v12.64h9.76v30.4h13.52V16h-13.52Zm-36.64 52.72V55.28h22.56V42.96h-22.56V30h25.76V17.6h-39.28v63.52h26.48c6.56 0 13.52-1.2 17.68-3.28v-12.4c-4.16 2-11.2 3.28-17.68 3.28h-12.96Z"
      fill="#000"
    />
    <path
      d="m330.249 0 24.248 14v28l-24.248 14L306 42V14l24.249-14Z"
      fill="#30C586"
    />
    <path d="M318.249 16h24v24h-24V16Z" fill="#fff" />
  </svg>
);
export default SvgIcoLogoWhite;
