/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicResponseGetInstagramInventory } from '../models/BasicResponseGetInstagramInventory';
import type { BasicResponseGetNaverBlogInventory } from '../models/BasicResponseGetNaverBlogInventory';
import type { BasicResponseListGetInstagramInventory } from '../models/BasicResponseListGetInstagramInventory';
import type { BasicResponseListGetNaverBlogInventory } from '../models/BasicResponseListGetNaverBlogInventory';
import type { BasicResponseVoid } from '../models/BasicResponseVoid';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SnsApiService {

    /**
     * SNS 채널 - 인스타그램 분석 API :: keyword (인스타그램 주소), type (U:로그인사용자의 데이터, 공백:경쟁사 데이터
     * @returns BasicResponseGetInstagramInventory OK
     * @throws ApiError
     */
    public static getInstagramAnalysis({
        keyword = '',
        type = '',
    }: {
        keyword?: string,
        type?: string,
    }): CancelablePromise<BasicResponseGetInstagramInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sns-channel/instagram/search',
            query: {
                'keyword': keyword,
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * SNS 채널 - 인스타그램 분석 API :: 사용자 저장 목록
     * @returns BasicResponseListGetInstagramInventory OK
     * @throws ApiError
     */
    public static getMemberInstagramAnalysisList(): CancelablePromise<BasicResponseListGetInstagramInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sns-channel/instagram/member/search',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * SNS 채널 - 네이버 블로그 분석 API :: keyword (블로그 주소), type (U:로그인사용자의 데이터, 공백:경쟁사 데이터
     * @returns BasicResponseGetNaverBlogInventory OK
     * @throws ApiError
     */
    public static getNaverBlogAnalysis({
        keyword = '',
        type = '',
    }: {
        keyword?: string,
        type?: string,
    }): CancelablePromise<BasicResponseGetNaverBlogInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sns-channel/blog/search',
            query: {
                'keyword': keyword,
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * SNS 채널 - 네이버 블로그 분석 API :: 사용자 저장 목록
     * @returns BasicResponseListGetNaverBlogInventory OK
     * @throws ApiError
     */
    public static getMemberNaverBlogAnalysisList(): CancelablePromise<BasicResponseListGetNaverBlogInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sns-channel/blog/member/search',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * SNS 채널 - 인스타그램 분석 API :: 사용자 검색 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteMemberInstagramAnalysis({
        id,
    }: {
        id: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sns-channel/instagram/member/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * SNS 채널 - 네이버 블로그 분석 API :: 사용자 검색 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteMemberNaverBlogAnalysis({
        id,
    }: {
        id: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sns-channel/blog/member/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
