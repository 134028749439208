import { atom } from 'jotai';
import { MainUiTypes } from '@src/types';

//atom

const initialValue = {
  isVisibleChannelTalk: false,
};

export const mainScope = Symbol('mainScope');

export const mainAtom = atom<MainUiTypes>(initialValue);
