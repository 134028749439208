import { useCallback } from 'react';
import {
  setPersistence,
  browserSessionPersistence,
  signInWithCustomToken,
  getAuth,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '@src/constants/firebaseConstant';

const app = initializeApp(firebaseConfig, process.env.REACT_APP_NAME);
const authService = getAuth(app);

export const useFirebase = () => {
  return { authService };
};

export const useSignInToken = () => {
  const func = useCallback(
    async (token: string) => {
      //세션에 토큰 정보를 저장한다.
      await setPersistence(authService, browserSessionPersistence);

      //custom token을 이용해서 로그인
      return await signInWithCustomToken(authService, token);
    },
    [authService],
  );

  return func;
};

export const useGetAccessToken = async () => {
  let accessToken = null;
  if (!!authService.currentUser) {
    accessToken = await authService.currentUser?.getIdToken();
  }

  return accessToken;
};
