import * as React from 'react';
import { SVGProps } from 'react';
const SvgEmptyBlog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M127.461 79.076c-.355-.454-1.205-.661-1.659-.208-1.778 1.766-3.565 3.541-5.649 4.952-1.472.996-4.524 2.93-6.005 1.055-.731-.917-.78-2.13-.672-3.255 0-.059.02-.118.02-.167 2.588-2.713 4.681-5.87 5.6-9.548.197-.79-.306-1.795-1.274-1.598-2.321.463-4.02 2.14-5.027 4.212-.553 1.144-.869 2.387-1.126 3.62-.158.74-.316 1.489-.445 2.248l-.711.681c-1.56 1.44-3.259 2.732-5.007 3.926-1.412.966-3.239 2.249-5.047 2.032-.671-.08-1.106-.424-1.373-.888a25.134 25.134 0 0 0 3.26-4.528 23.963 23.963 0 0 0 1.59-3.373c.415-1.085.899-2.318.938-3.491.04-1.125-.356-2.309-1.511-2.723-1.274-.454-2.578.493-3.447 1.342-1.758 1.706-2.627 3.984-3.12 6.342-.376 1.795-.742 3.935-.406 5.82a23.328 23.328 0 0 1-2.044 1.794c-.583.454-1.264 1.026-1.995 1.303-.099-1.194-2.084-1.302-2.33.049-.07.365-.13.73-.198 1.095-.119.67.444 1.154 1.027 1.292 1.471.335 2.844-.414 4.03-1.223a23.045 23.045 0 0 0 2.478-1.993c1.274 1.588 3.506 1.825 5.501 1.214 2.321-.71 4.405-2.328 6.301-3.788a43.646 43.646 0 0 0 1.976-1.608c.296 1.766 1.224 3.433 3.012 4.084 2.39.868 4.968-.454 6.953-1.717 2.301-1.45 4.227-3.393 6.133-5.306.444-.454.632-1.105.207-1.657l.02.01Zm-27.515-1.598c.345-.81.8-1.578 1.412-2.21.286-.296.612-.572.958-.799.03-.02.099-.049.158-.079-.03-.118-.049-.286-.039-.286 0 0 .059.148.059.158 0 .04 0 .079-.01.118.069.188.03.148 0 .03-.119 1.766-.899 3.521-1.679 5.1a22.265 22.265 0 0 1-2.035 3.294c.168-1.815.465-3.64 1.186-5.326h-.01Zm14.785-1.41c.256-.642.582-1.273.997-1.816a19.14 19.14 0 0 1-.997 1.815Z"
      fill="#24C185"
    />
    <path
      d="M140.587 75.09c-5.334.632-10.479 3.098-14.459 6.678-2.015 1.815-3.773 3.916-5.146 6.254-1.501 2.564-2.607 5.395-4.573 7.664-1.728 2.012-4.691 3.501-7.278 2.278-.939-.444-1.985.69-1.561 1.598.909 1.924 1.748 3.896 2.746 5.77.701 1.332 2.785.503 2.182-.927-.503-1.213-1.066-2.397-1.639-3.58 1.837.049 3.674-.622 5.225-1.657 2.498-1.667 3.99-4.36 5.353-6.954 1.333-2.545 2.715-4.962 4.671-7.082a24.868 24.868 0 0 1 6.202-4.853c2.687-1.48 5.561-2.427 8.593-2.9 1.481-.227 1.155-2.466-.336-2.288h.02Z"
      fill="#2E3338"
    />
    <path
      d="M127.51 152.372c-1.244-4.478-2.35-8.996-3.496-13.504-2.607.03-5.116-.71-7.387-2.031-4.662-2.713-7.723-7.605-8.751-12.823-.385-1.923-.513-3.876-.454-5.82.03-.848.089-1.696.188-2.535.405-3.669 1.402-7.308 2.597-10.761.504-1.45 2.696-.572 2.212.868-1.027 3.028-1.866 6.135-2.281 9.302-.02.128-.03.256-.049.384-.089.72-.148 1.44-.188 2.17-.227 4.587.642 9.243 3.447 12.991 2.657 3.551 6.854 6.224 11.427 5.78.721-.069 1.215.375 1.392 1.045 1.225 4.764 2.529 9.509 3.664 14.302.316 1.332-1.945 1.953-2.321.642v-.01ZM127.145 96.544a1.196 1.196 0 0 0-.454-.77c-.247-.167-.563-.276-.869-.216-.198.039-.415.118-.583.256l-.217.128a1.138 1.138 0 0 0-.366.493c-.336.661-.671 1.322-.997 1.983-.119.237-.099.641.01.878.118.237.276.424.513.552.217.119.474.188.731.148.286-.05.612-.197.77-.453.415-.71.85-1.411 1.255-2.131a.866.866 0 0 0 .187-.424.842.842 0 0 0 .03-.444h-.01ZM138.493 94.67c-1.264-4.863-6.173-8.276-11.18-7.753-.622.069-1.077.71-.988 1.322.099.65.692 1.045 1.324.986 3.891-.355 7.506 2.357 8.582 6.076.158.562.899.819 1.413.67.513-.147 1.007-.72.859-1.301h-.01Z"
      fill="#2E3338"
    />
    <path
      d="M115.59 108.36c-.198-.236-.474-.424-.79-.443-.326-.02-.573.069-.85.236-1.382.829-2.716 1.746-4.069 2.624-.503.325-.582 1.174-.207 1.618.434.513 1.076.552 1.62.207 1.363-.868 2.755-1.697 4.088-2.604.504-.345.603-1.174.208-1.638ZM141.93 97.984c-3.536-2.515-5.837-6.707-5.857-11.077-.029-4.409 2.193-8.64 5.728-11.234 1.215-.898-.217-2.723-1.432-1.845-3.249 2.338-5.471 5.85-6.33 9.745-.869 3.897-.079 8 1.935 11.422a16.363 16.363 0 0 0 2.993 3.69c-3.16 1.834-5.412 5.257-5.61 8.926-.247 4.498 2.39 8.769 6.42 10.712 1.392.671 2.271-1.569.879-2.239-3.082-1.49-5.156-4.981-4.889-8.414.277-3.452 2.676-6.461 5.916-7.605.859-.305.968-1.558.257-2.071l-.01-.01Z"
      fill="#2E3338"
    />
    <path
      d="M152.665 117.494c-1.086-2.436-3.328-4.32-6.034-4.586-2.874-.277-5.946 1.154-7.18 3.836-.543 1.174 1.363 2.279 2.025 1.125.918-1.598 2.478-2.584 4.355-2.604 1.867-.02 3.506 1.105 4.385 2.722 2.074 3.847-.494 8.197-4.405 9.469-2.558.829-5.027.168-7.288-1.104-1.304-.73-2.628.887-1.423 1.903 2.223 1.865 5.738 2.387 8.504 1.776 2.736-.612 5.205-2.318 6.607-4.764 1.363-2.387 1.59-5.248.454-7.773Z"
      fill="#2E3338"
    />
    <path
      d="M147.51 119.368c-.296-.621-.928-.838-1.561-.631-1.955.641-3.881 1.361-5.817 2.042-.582.207-.889.996-.632 1.548.287.612.929.848 1.551.631 1.945-.67 3.901-1.311 5.827-2.031.583-.217.899-.997.632-1.559ZM140.646 135.485c-2.341 3.679-6.41 5.435-9.758 7.99-2.716 2.071-5.531 5.76-3.703 9.282.424.818-.396 1.735-1.235 1.647-3.546-.365-7.239 1.124-9.452 3.945-1.363 1.746-2.113 3.906-2.192 6.086-.04.907.039 1.805.227 2.693-.711.414-1.432.838-2.153 1.252a12.562 12.562 0 0 1-.444-2.554c-.228-3.157.651-6.392 2.637-8.917 2.35-3.008 6.024-4.794 9.807-4.942-.326-1.538-.119-3.176.454-4.665 1.57-4.103 5.353-6.293 8.839-8.591 3.121-2.062 6.943-5.218 6.104-9.44-.296-1.469 2.005-1.795 2.301-.335.474 2.298-.217 4.587-1.462 6.53l.03.019Z"
      fill="#2E3338"
    />
    <path
      d="M204.378 149.502c-1.828 2.041-4.395 3.057-7.082 2.308-1.442-.405-.81-2.851.632-2.535.958.217 1.857.108 2.716-.375 1.808-1.006 3.013-3.137 2.973-5.198-.049-2.555-1.442-4.873-3.022-6.796-1.709-2.072-3.802-3.768-5.728-5.623-2.055-1.982-3.882-4.103-5.077-6.717-2.311-5.03-2.261-10.702-2.795-16.097-.434-4.399-1.531-8.64-3.318-12.665a38.792 38.792 0 0 0-.682-1.46 39.697 39.697 0 0 0-1.59-2.939c-4.434-7.496-11.189-13.632-19.219-17.084a41.832 41.832 0 0 0-11.526-3.077c-6.656-.809-13.51-.06-19.782 2.259-4.8 1.775-9.52 4.448-12.671 8.571-3.081 4.014-4.395 9.607-2.202 14.342.632 1.36-1.502 2.249-2.144.907a13.702 13.702 0 0 1-1.185-3.975c-.79-5.71 1.778-11.215 5.778-15.16 3.97-3.916 9.303-6.451 14.627-7.97a45.623 45.623 0 0 1 17.046-1.49c.85.08 1.699.188 2.538.326 4.83.75 9.531 2.288 13.857 4.606 5.926 3.186 11.041 7.724 14.972 13.139a44.905 44.905 0 0 1 5.531 10.07 47.313 47.313 0 0 1 2.252 7.595c.573 2.772.79 5.603 1.057 8.414.256 2.791.533 5.652 1.392 8.325.879 2.742 2.37 5.04 4.365 7.112 3.951 4.123 9.156 7.595 10.301 13.582.524 2.692-.158 5.513-2.005 7.585l-.009.02Z"
      fill="#2E3338"
    />
    <path
      d="M207.528 174.003c-1.027 4.241-3.368 7.97-6.716 10.771-6.222 5.218-15.436 6.904-22.982 3.748-2.36 2.959-5.985 4.804-9.738 5.257-3.674.434-7.526-.414-10.459-2.712-3.476-2.713-5.461-6.796-6.992-10.83-1.857-4.863-3.536-9.805-5.284-14.707-1.106-3.087-2.222-6.175-3.328-9.272-.336-.937-.672-1.874-1.008-2.801 1.027.197 2.045.523 3.022 1.026l.771 2.16c1.056 2.949 2.113 5.908 3.18 8.857 1.669 4.656 3.259 9.351 5.047 13.967 1.402 3.63 3.15 7.388 6.36 9.765 5.215 3.857 13.412 2.328 17.007-3.008.286-.434.988-.641 1.462-.424 3.911 1.864 8.315 2.209 12.523 1.292 7.456-1.628 13.688-7.329 14.962-14.983 1.255-7.526-1.827-15.525-8.118-19.944-1.244-.878.158-2.703 1.412-1.884 3.99 2.614 6.884 6.786 8.326 11.294 1.284 4.004 1.541 8.315.543 12.408l.01.02Z"
      fill="#2E3338"
    />
    <path
      d="M144.833 156.653c-.316.355-.78.532-1.264.276a10.9 10.9 0 0 0-1.531-.661c-5.836-1.992-11.802 1.687-16.799 4.606-3.565 2.081-7.121 4.163-10.686 6.244-.711.414-1.432.838-2.153 1.253-7.328 4.28-14.666 8.561-21.995 12.852-8.118 4.744-16.227 9.528-24.355 14.263-.77.453-1.53.887-2.3 1.341a1403.885 1403.885 0 0 1-8.218 4.735c-1.165.67-2.33-1.352-1.195-2.032a1371.65 1371.65 0 0 1 9.126-5.386c.77-.454 1.53-.888 2.3-1.341 2.885-1.677 5.769-3.354 8.643-5.041 11.812-6.904 23.624-13.818 35.446-20.723.701-.404 1.402-.819 2.103-1.233.79-.464 1.581-.927 2.361-1.391 4.385-2.594 8.77-5.208 13.224-7.693 4.039-2.249 8.908-4.143 13.491-3.275 1.027.197 2.044.523 3.022 1.026.227.108.445.227.662.365.691.404.573 1.282.109 1.795l.009.02ZM38.88 114.594c-.068.582-.67 1.144-1.283 1.016-2.884-.582-5.748-1.223-8.622-1.834-.978-.217-2.193-.642-3.19-.365-.711.197-1.027.848-1.146 1.529-.158.887-.079 1.785.03 2.673.128 1.114.316 2.229.691 3.284.632 1.776 1.817 3.512 3.704 4.103a3.39 3.39 0 0 0 1.372.129c1.057-.109 2.065-.701 2.48-1.707.118-.286.296-.483.513-.592-.355 1.362-.672 2.762-.879 4.153a5.85 5.85 0 0 1-2.499.552 6.345 6.345 0 0 1-1.659-.227c-2.568-.739-4.395-3.038-5.264-5.464-.484-1.371-.74-2.811-.909-4.261-.118-1.154-.158-2.318.119-3.442.296-1.194.978-2.239 2.104-2.802 1.234-.611 2.656-.414 3.96-.138 3.15.681 6.301 1.371 9.452 2.101.612.148 1.096.602 1.027 1.282v.01Z"
      fill="#2E3338"
    />
    <path
      d="M37.41 141.936c-.218.631-.979 1.085-1.561.759-3.29-1.824-5.205-5.572-5.778-9.508-.277-1.884-.237-3.778 0-5.652.089-.809.227-1.608.385-2.407.415-2.091 1.008-4.152 1.58-6.154.465-1.618 2.637-.642 2.203.956-.267.957-.533 1.924-.79 2.9-.356 1.361-.672 2.762-.88 4.153-.266 1.775-.365 3.561-.157 5.356.375 3.117 1.738 6.322 4.325 7.871.573.345.949.996.682 1.736l-.01-.01Z"
      fill="#2E3338"
    />
    <path
      d="m32.402 118.984-3.793-1.154c-.296-.089-.582-.109-.869.009-.266.109-.533.365-.632.642-.187.562.04 1.302.652 1.499 1.254.404 2.518.799 3.773 1.193.296.089.592.109.889-.009.266-.119.543-.375.642-.651.197-.573-.04-1.332-.672-1.529h.01ZM41.607 112.01c-.149 0-.307-.02-.455-.03-.434-.019-.78-.335-.948-.72-1.244-.789-2.489-1.578-3.743-2.367-1.333-.848-2.667-1.874-4.158-2.426-.089-.03-.178-.06-.257-.089-.889-.266-1.698-.128-2.36.404a2.826 2.826 0 0 0-.632.681c-.86 1.233-.919 2.86-1.027 4.31-.119 1.499-2.44 1.233-2.331-.266.138-1.933.365-3.916 1.52-5.534a5.183 5.183 0 0 1 1.956-1.657 4.56 4.56 0 0 1 2.36-.434c.05 0 .09 0 .13.01 2.004.217 3.742 1.529 5.392 2.565 1.807 1.144 3.604 2.278 5.402 3.422 1.076.681.237 2.19-.85 2.141v-.01Z"
      fill="#2E3338"
    />
    <path
      d="M48.411 107.127c-2.479-3.067-5.61-6.076-9.56-6.983-3.496-.809-7.496.671-8.889 4.143-.563 1.391 1.61 2.249 2.173.848 1.087-2.663 4.336-3.314 6.845-2.515 3.239 1.035 5.283 3.62 7.555 5.967 1.037 1.075 2.864-.236 1.876-1.46Z"
      fill="#2E3338"
    />
    <path
      d="M74.89 67.693c-.563 2.357-2.568 3.797-4.711 4.656-2.568 1.025-5.324 1.696-7.97 2.495-2.924.888-5.837 1.785-8.76 2.673-1.304.395-2.618.8-3.922 1.194-.75.227-1.5.463-2.252.69-.246-.67-.592-1.282-1.096-1.756-.889-.808-2.696-.986-2.913.493-.119.77.03 1.608.168 2.407-.76.207-1.55.375-2.341.454-1.146.118-2.291.03-3.358-.434-2.499-1.085-3.16-3.64-3.753-6.056-.405-1.697-.82-3.383-1.234-5.08-.08-.257-.149-.503-.228-.75a172.525 172.525 0 0 1-3.358-11.935c-.572-2.337-2.557-6.736-1.55-9.074 1.53-3.551 7.259-4.28 10.538-4.863 5.64-.996 11.249-2.18 16.869-3.334 1.442-.296 2.883-.621 4.335-.887 1.255-.237 2.578-.395 3.852-.188 2.548.424 4.188 2.249 5.126 4.537.355.868.671 1.746.948 2.654.247.779.474 1.568.701 2.357a50 50 0 0 0-2.35.513c-.751.187-1.63.444-2.183 1.016-.326.325-.632.937-.454 1.41.197.513.918.592 1.372.632.84.079 1.68.02 2.519 0l2.123-.03c.237.8.484 1.598.731 2.397.583 1.913 1.165 3.837 1.748 5.75.77 2.526 2.045 5.376 1.403 8.059Z"
      fill="#FFD000"
    />
    <path
      d="M66.06 195.505c-.77.454-1.53.888-2.3 1.342a244.55 244.55 0 0 0-.287-2.673 1547.454 1547.454 0 0 0-2.884-25.291c-.484-3.945-.988-7.891-1.511-11.826-.1-.809-.208-1.628-.316-2.436-.652-4.922-1.334-9.844-2.035-14.756a1526.951 1526.951 0 0 0-4.434-29.088c-.79-4.863-2.183-15.88-3.022-20.733-.405-2.387-.83-4.764-1.255-7.142-.187-1.065-.326-2.367-.73-3.491-.248-.671-.593-1.282-1.097-1.756-.889-.809-2.696-.986-2.913.493-.119.77.03 1.608.168 2.407.049.256.088.503.118.75l.504 3.659c.661 4.833 1.906 15.821 2.518 20.664.198 1.499-2.103 1.835-2.32.336-.188-1.263-.356-2.535-.534-3.798-.681-5-1.926-15.999-2.627-21.157l-.03-.207c-.276-2.013-.543-4.449 1.511-5.643 1.56-.907 3.684-.542 5.037.582.948.79 1.531 1.845 1.916 2.99.287.828.464 1.696.622 2.534.465 2.486.89 4.971 1.324 7.467.879 5.04 2.31 16.245 3.13 21.295a1325.9 1325.9 0 0 1 2.766 17.577c.119.789.247 1.588.355 2.367a966.14 966.14 0 0 1 1.541 10.545 1220.9 1220.9 0 0 1 1.818 13.246l.325 2.447c.642 4.902 1.265 9.824 1.857 14.736.889 7.289 1.719 14.588 2.489 21.897.099.888.188 1.785.286 2.673l.01-.01Z"
      fill="#2E3338"
    />
    <path
      d="M130.157 204.097a320.37 320.37 0 0 1-3.98 2.189c-10.706 5.84-21.668 11.235-32.76 16.334a573.134 573.134 0 0 1-16.74 7.388c-2.805 1.184-5.62 2.387-8.464 3.492-2.745 1.065-5.57 2.022-8.493 2.466-2.884.434-5.857.325-8.612-.661-2.292-.809-4.297-2.219-6.015-3.916-3.763-3.758-5.67-8.857-6.538-14.006-.484-2.89-.662-5.82-.8-8.739-.139-3.117-.267-6.244-.385-9.351-.247-6.421-.494-12.842-.751-19.264-.197-4.823-.405-9.646-.602-14.469l-.1-2.506c-.296-7.082-.572-14.164-.839-21.256-.02-.631.316-1.213.988-1.312.583-.079 1.284.355 1.313.987.08 1.529.149 3.057.218 4.596.257 5.366.484 10.732.71 16.107.03.839.07 1.667.11 2.506.256 6.293.523 12.586.81 18.879.286 6.224.572 12.447.839 18.671.257 5.869.217 11.906 1.66 17.636 1.145 4.518 3.535 8.927 7.456 11.6 4.563 3.117 10.182 2.397 15.15.74 2.825-.937 5.57-2.121 8.316-3.255a599.291 599.291 0 0 0 8.237-3.502 574.384 574.384 0 0 0 16.295-7.368c10.835-5.099 21.491-10.564 32.019-16.265.603-.325 1.393.089 1.64.661.286.671-.079 1.302-.662 1.637l-.02-.019Z"
      fill="#2E3338"
    />
    <path
      d="M140.636 225.915c-4.711-.365-9.195-2.249-12.721-5.396-3.367-2.998-5.678-7.161-6.587-11.56-.296-1.46-2.588-1.144-2.301.336 1.55 7.9 7.101 14.598 14.646 17.478a23.945 23.945 0 0 0 6.696 1.499c1.511.118 1.798-2.229.267-2.347v-.01Z"
      fill="#2E3338"
    />
    <path
      d="M148.962 238.096c-2.43-3.777-4.879-7.545-7.319-11.323-.345-.533-1.175-.572-1.649-.207-.533.414-.553 1.114-.207 1.647 2.429 3.778 4.859 7.565 7.298 11.333.346.543 1.195.582 1.669.207.544-.424.553-1.124.208-1.667v.01ZM115.353 49.406c-2.519 3.492-5.175 7.25-8.988 9.44-4.039 2.317-9.214 2.288-13.115-.336a12.903 12.903 0 0 1-4.593-5.524c-.592-1.38-2.726-.483-2.153.908 1.985 4.764 6.41 8.226 11.545 8.936 5.413.75 10.282-1.696 13.995-5.474 1.956-1.993 3.605-4.242 5.215-6.51.889-1.243-1.007-2.663-1.906-1.42v-.02Z"
      fill="#2E3338"
    />
    <path
      d="M93.99 53.48c-2.676.147-5.372.118-8.059.157-2.597.04-5.185.09-7.772.129-2.144.039-4.277.078-6.42.108h-.494l-3.456.06c-.909.019-1.808.029-2.697-.198-1.866-.493-2.943-2.22-2.44-4.113.514-1.943 2.233-2.94 4.07-3.433.84-.227 1.679-.434 2.538-.611 3.427-.74 6.953-1.135 10.42-1.41 4.533-.366 9.125-.405 13.668-.04 1.502.128 1.215 2.387-.266 2.298-4.543-.247-9.077-.227-13.61.168-3.18.286-6.37.7-9.5 1.331-.781.148-1.571.326-2.351.513-.75.188-1.63.444-2.183 1.016-.326.326-.632.937-.454 1.41.197.514.918.593 1.373.632.839.079 1.678.02 2.518 0l2.123-.03c3.418-.049 6.845-.108 10.262-.157 4.217-.07 8.464-.277 12.69-.178 1.344.03 1.364 2.259.03 2.338l.01.01Z"
      fill="#2E3338"
    />
    <path
      d="M91.057 37.53c-2.163-.838-4.869-.325-6.676 1.095a7.154 7.154 0 0 0-2.697 5.977c.03.573.633 1.007 1.166.987.523-.02 1.136-.434 1.136-1.016 0-.957.108-1.529.484-2.348.04-.089.088-.177.128-.266.168-.266.286-.454.415-.621.118-.158.257-.316.395-.464a.979.979 0 0 0 .108-.099c.09-.078.178-.157.277-.227a4.681 4.681 0 0 1 2.064-.937c.484-.088.78-.108 1.175-.078.208.01.415.039.613.069 0 0 .237.049.227.04.089.019.247.058.326.088.612.158 1.254-.03 1.51-.67.228-.593-.068-1.293-.66-1.52l.01-.01Z"
      fill="#2E3338"
    />
    <path
      d="M92.213 31.444c-2.193-.65-4.652-.05-6.262 1.588-1.64 1.667-2.143 4.074-1.481 6.283.177.582.997.868 1.52.622.633-.296.8-.898.623-1.52.069.267-.01-.068-.02-.128-.02-.108-.04-.217-.05-.325-.01-.099-.02-.187-.029-.286v-.118c0-.208.02-.415.04-.622.01-.098.03-.187.039-.286.01-.079.04-.197.03-.158.088-.345.207-.789.424-1.065-.187.247.05-.079.09-.128.049-.079.108-.158.167-.237.06-.079.119-.148.178-.227.01-.02.128-.128.168-.177.059-.05.138-.119.148-.138.069-.06.148-.119.217-.178.07-.06.148-.108.227-.168.04-.03.09-.059.129-.079.178-.088.345-.177.523-.256.09-.04.178-.069.267-.099.02 0 .188-.059.237-.078.069-.02.188-.04.197-.04.09-.02.188-.03.277-.05l.286-.029c.02 0 .188 0 .247-.01h.208c.098 0 .187.01.286.03.099.01.188.03.277.04.049 0 .454.098.168.029.592.148 1.303-.01 1.53-.67.188-.563-.04-1.342-.671-1.53l.01.01Z"
      fill="#2E3338"
    />
    <path
      d="M100.558 26.148c-5.471-2.595-12.197.808-13.896 6.44-.178.602.04 1.273.662 1.51.553.217 1.323-.06 1.511-.661.622-2.012 1.975-3.798 3.852-4.804 2.222-1.193 4.69-1.154 7.012-.296 1.432.533 2.222-1.548.859-2.2v.01Z"
      fill="#2E3338"
    />
    <path
      d="M225.039 234.427c.029.75-.366 1.47-1.185 1.578-.682.089-1.561-.424-1.59-1.184-.07-2.495-.494-19.381-.563-21.887-.603-20.171-1.482-40.342-2.42-60.503a5141.36 5141.36 0 0 0-3.18-60.464c-.593-10.11-1.195-20.22-1.818-30.32-.474-7.694-.701-15.694-3.427-22.992-1.224-3.295-3.012-6.451-5.59-8.878-2.874-2.693-6.498-4.36-10.32-5.188-4.01-.868-8.148-.976-12.227-.878-4.701.119-9.392.395-14.084.602-1.669.079-3.338.148-5.007.227-.78.03-1.56.079-2.331.108-5.126.227-10.854.562-16.859.927-.839.05-1.688.099-2.538.158-14.192.878-29.55 1.914-41.53 2.387-1.49.06-1.846-2.249-.335-2.318 2.498-.128 5.007-.246 7.496-.365 9.619-.444 22.123-1.312 33.915-2.071.879-.06 1.748-.109 2.607-.168 6.203-.394 12.118-.74 17.225-.976.78-.04 1.56-.08 2.33-.109l12.968-.592c8.177-.364 17.046-1.006 24.671 2.585 7.081 3.334 11.111 9.932 13.096 17.27 2.192 8.099 2.301 16.7 2.844 25.015a3980.923 3980.923 0 0 1 1.886 30.705 5289.386 5289.386 0 0 1 3.151 61.46c.928 20.487 2.044 55.365 2.815 75.871Z"
      fill="#2E3338"
    />
    <path
      d="M182.274 49.524c-.079.7-.681 1.056-1.343 1.066-4.207.03-8.414-.06-12.622-.09l-4.395-.029c-.8 0-1.609-.01-2.409-.02-3.704-.02-8.04.05-12.642.158l-2.439.06c-10.499.266-22.005.67-30.291.532-.642-.01-1.106-.72-1.037-1.302.079-.68.672-1.045 1.304-1.045 4.207 0 15.861-1.075 20.068-1.036 3.141.02 6.291.05 9.432.079.82 0 1.64.01 2.459.02l10.489.088c.889 0 1.768.01 2.657.02.8 0 1.6 0 2.39.01 5.777.02 11.555.02 17.323.158.661.01 1.135.73 1.066 1.341l-.01-.01Z"
      fill="#2E3338"
    />
    <path
      d="M183.835 90.39c-.04 1.804-.099 3.61-.168 5.414a38.772 38.772 0 0 0-.681-1.46 40.083 40.083 0 0 0-1.591-2.939c.04-1.529.07-3.058.079-4.596.149-12.409-.385-24.807-1.353-37.196-.118-1.5 2.173-1.844 2.321-.335.415 4.162.702 8.334.939 12.517.513 9.518.661 19.056.454 28.594ZM163.914 50.461c-.8 0-1.609-.01-2.409-.02 0-.808 0-1.627-.01-2.436V46.96c-.059-7.437-.119-14.884-.188-22.322 0-.808-.01-1.617-.02-2.426.781-.04 1.561-.079 2.331-.108 0 .808.02 1.617.02 2.426v.621c.049 5.07.089 10.13.138 15.19.02 2.555.069 5.11.099 7.664l.03 2.437.009.02ZM61.418 156.189c-.78.286-1.56.572-2.35.868-6.884 2.535-13.778 5.07-20.661 7.605-.8.296-1.6.592-2.39.878l-.1-2.506c.317-.118.623-.226.94-.345l1.45-.532c6.43-2.368 12.86-4.735 19.29-7.112.394-.148.78-.286 1.165-.434.78-.286 1.56-.582 2.33-.868.11.809.218 1.627.326 2.446ZM32.046 106.378c-.889-.266-1.698-.128-2.36.404l-.385-1.864c-.04-.197-.08-.404-.129-.602-2.42-11.757-4.839-23.524-7.269-35.282-2.014-9.755-4.03-19.51-6.014-29.275-.158-.77-.316-1.529-.464-2.298-.287-1.39-.573-2.782-.85-4.172a.97.97 0 0 1 .02-.563c.227-.73 1.234-.976 1.837-.532.198.148.356.384.425.7.286 1.352.563 2.703.84 4.054.157.76.325 1.529.473 2.288.958 4.587 1.906 9.174 2.854 13.77 2.47 11.955 4.938 23.92 7.408 35.884 1.027 5 2.064 9.992 3.09 14.992.179.839.347 1.667.524 2.506v-.01ZM110.138 115.068c-.02.128-.03.256-.049.384-.089.72-.148 1.44-.188 2.17-.82.198-1.649.395-2.479.582-6.113 1.44-12.217 2.89-18.33 4.33-10.45 2.466-20.908 4.952-31.358 7.408l-2.37.562-.355-2.367 2.37-.562 11.348-2.693c12.957-3.068 25.915-6.145 38.883-9.213.839-.197 1.679-.394 2.528-.601Z"
      fill="#2E3338"
    />
    <path
      d="M144.438 25.575c-.839.05-1.689.099-2.538.158a197.19 197.19 0 0 0-.454-2.367c-.761-3.936-1.59-7.822-2.529-11.452-.118-.483-.246-.957-.375-1.44-.079-.286-.148-.562-.227-.828-1.896-6.816-3.012-7.862-9.58-6.796-4.612.75-9.175 1.923-13.738 2.92-17.422 3.797-34.844 7.604-52.265 11.402-8.711 1.903-17.422 3.807-26.133 5.7l-13.204 2.88c-1.768.386-3.704.711-5.225 1.766-1.165.82-2.252 2.348-1.748 3.847.099.306.079.592-.02.819-.296.71-1.294 1.055-1.837.533a1.087 1.087 0 0 1-.286-.454c-.692-2.012-.05-4.153 1.402-5.682 1.55-1.637 3.714-2.327 5.867-2.83 4.977-1.164 10.004-2.2 15.002-3.295 18.172-3.985 36.335-7.96 54.507-11.935l27.249-5.977c4.079-.888 8.256-1.46 12.365-2.19 5.323-.947 8.098-.394 9.54 6.698.129.66.287 1.341.455 2.051.177.74.365 1.5.562 2.279.988 3.935 2.203 8.275 2.815 11.796.04.198.069.395.099.592.089.602.188 1.194.286 1.796l.01.01ZM153.218 71.638a37.424 37.424 0 0 0-2.567-.384c-.178-.839-.366-1.677-.544-2.516a751.817 751.817 0 0 1-3.683-18.08c-.188-.917-.356-1.844-.534-2.771.82 0 1.64.01 2.459.02.168.897.336 1.795.514 2.692a958.39 958.39 0 0 0 1.817 9.164 959.483 959.483 0 0 0 1.966 9.3c.187.859.375 1.717.572 2.575Z"
      fill="#2E3338"
    />
    <path
      d="M141.239 11.392c-.771.177-1.541.345-2.312.522-8.987 2.032-17.974 4.064-26.952 6.106-17.6 3.975-35.199 7.96-52.798 11.945-13.67 3.087-27.328 6.184-40.997 9.281-.76.178-1.52.346-2.281.523-.158-.77-.316-1.529-.465-2.298.751-.178 1.511-.335 2.272-.513l47.742-10.8 52.799-11.955a14673.96 14673.96 0 0 1 20.088-4.547c.78-.178 1.57-.355 2.35-.533.178.74.366 1.5.563 2.279l-.009-.01Z"
      fill="#2E3338"
    />
    <path
      d="M86.01 108.074c-1.471 2.565-4.326 3.768-7.041 4.587-2.904.878-6.222 1.795-9.245.947-3.358-.937-4.908-3.827-5.659-7.003-1.027-4.35-1.906-8.74-2.795-13.119-.375-1.834-.76-3.67-1.264-5.445-.395-1.39-.444-2.712-.72-4.123-.03-.138-.06-.276-.09-.424-.128-.611.494-1.203 1.067-1.252 1.165-1.391 2.933-2.101 4.622-2.594 3.812-1.105 7.91-2.2 11.792-.858 3.338 1.153 4.998 4.083 5.886 7.318 1.037 3.748 1.936 7.536 2.904 11.304.86 3.344 2.4 7.388.524 10.662h.02Z"
      fill="#24C185"
    />
    <path
      d="M177.82 77.113c-.434-.513-1.116-.582-1.669-.207l-1.916 1.311c-.365-1.035-.75-2.07-1.126-3.107-.217-.582-.987-.887-1.54-.63-.633.295-.82.907-.633 1.538.465 1.549.939 3.087 1.403 4.636.109.355.474.592.81.68.365.09.701 0 1.007-.207.198-.069.356-.128.494-.227 1.007-.68 1.975-1.42 2.953-2.13.504-.365.612-1.184.207-1.667l.01.01Z"
      fill="#2E3338"
    />
    <path
      d="M115.837 98.014S107.096 81.729 128.943 73c21.846-8.73 40.482.868 49.796 12.507 9.323 11.629 9.027 20.654 9.609 25.014.583 4.359-1.748 12.507 4.366 16.58 6.113 4.074 14.271 12.212 12.523 17.163-1.748 4.942-6.578 6.047-6.578 6.047s17.936 18.967 2.499 31.474c-15.437 12.507-24.74 4.459-24.74 4.459s-16.029 23.465-28.553-17.252c-2.567-8.364-5.53-13.671-5.53-13.671s-7.279-1.746-15.723 3.196c-8.445 4.942-14.657 7.319-14.657 7.319s2.42-9.065 6.203-10.801c3.782-1.746 6.992-2.032 6.992-2.032s-2.617-6.401 4.948-10.475c7.575-4.074 9.027-6.401 9.61-8.729.582-2.328 1.165-5.238 1.165-5.238s11.644 1.45 11.062-7.851c-.583-9.311-9.324-6.106-9.324-6.106l-1.975 1.48s-5.886-.02-5.886-7.871c0-7.852 5.827-9.893 5.827-9.893s-7.575-5.524-6.114-11.63c1.452-6.105 3.783-9.31 3.783-9.31s-9.906.867-14.271 8.433c-4.366 7.565-8.158 12.21-8.158 12.21l.02-.01Z"
      fill="#2E3338"
    />
    <path
      d="M211.163 142.183c-1.462-7.497-9.62-9.716-14.825-13.947-2.854-2.328-4.977-5.603-5.925-9.164-.158-.611-.642-1.085-1.324-.986-.573.079-1.146.71-.988 1.322.8 3.117 2.262 6.017 4.395 8.433 2.292 2.594 5.195 4.301 8.138 6.047 2.657 1.588 5.432 3.343 7.092 6.046 1.511 2.476 1.58 5.681.069 8.187-1.699 2.811-5.067 4.162-8.257 3.56-1.55-.286-1.827 2.141-.276 2.437 3.209.601 6.725-.434 9.046-2.742 2.381-2.368 3.497-5.889 2.855-9.193Z"
      fill="#2E3338"
    />
    <path
      d="M212.219 170.087c-.049-2.614-.306-5.208-1.027-7.733-1.412-4.962-5.323-9.696-10.785-10.11-1.501-.119-1.768 2.17-.266 2.328 4.138.414 7.091 3.817 8.414 7.535.78 2.18 1.067 4.508 1.175 6.806.119 2.358.089 4.745-.286 7.082-.346 2.141-1.076 4.222-2.35 5.987-1.146 1.598-2.628 2.89-4.277 3.956-3.476 2.229-7.674 3.205-11.763 3.314a26.86 26.86 0 0 1-7.466-.858c-2.528-.661-4.76-1.795-7.121-2.88-1.254-.582-2.281 1.311-1.175 2.11 2.005 1.45 4.513 2.368 6.884 3.038 2.429.681 4.928 1.085 7.446 1.145 4.701.098 9.511-.839 13.62-3.206 4.138-2.387 7.19-6.007 8.325-10.692.623-2.555.692-5.179.642-7.792l.01-.03ZM125.832 151.504c-4.089-1.164-8.326-1.519-12.119.74-3.318 1.972-5.521 5.523-5.728 9.38-.118 2.209.277 4.813 1.59 6.658.81 1.134 2.558.039 2.064-1.203-.405-1.016-.859-1.934-1.047-3.029a9.988 9.988 0 0 1-.029-3.205 9.062 9.062 0 0 1 2.577-5.041c1.324-1.302 3.023-2.268 4.85-2.623 2.429-.474 4.898.01 7.279.582 1.274.305 1.837-1.894.563-2.259Z"
      fill="#2E3338"
    />
    <path
      d="M23.889 30.961a2.104 2.104 0 0 0-.474-.78c-.119-.118-.238-.246-.376-.345-.128-.098-.286-.177-.425-.246-.069-.03-.138-.07-.207-.089-.336-.108-.612-.138-.968-.108-.069 0-.148.02-.217.04-.158.038-.326.078-.474.157-.06.03-.109.06-.158.089-.089.04-.178.088-.277.158-.355.226-.661.65-.79 1.055-.138.414-.187.878-.089 1.302.05.217.109.424.218.621.168.306.316.503.582.74.05.04.1.079.148.109.139.078.287.167.435.226.198.07.425.129.632.139.444.01.899-.05 1.284-.267.128-.069.277-.148.395-.246.128-.099.237-.227.346-.346.148-.167.266-.365.355-.572.03-.059.05-.118.06-.177.039-.158.088-.326.098-.484.02-.345-.03-.621-.138-.956l.04-.02ZM31.987 29.787a3.069 3.069 0 0 0-.158-.592 2.5 2.5 0 0 0-.346-.611 2.663 2.663 0 0 0-1.135-.868 1.994 1.994 0 0 0-.326-.089c-.119-.03-.227-.06-.356-.069-.355-.02-.612.02-.948.138-.109.03-.188.089-.286.148-.09.05-.178.099-.247.158a2.132 2.132 0 0 0-.543.71c-.188.355-.208.77-.188 1.164.01.207.079.414.138.612.07.197.178.384.306.542.208.247.435.414.721.552.287.129.583.139.89.129a.85.85 0 0 0 .414-.158.752.752 0 0 0 .188-.119c.069 0 .138 0 .197-.01.227-.029.454-.088.662-.177.148-.06.276-.148.415-.227.187-.118.335-.266.444-.463.099-.188.148-.365.148-.582v-.178l.01-.01Z"
      fill="#24C185"
    />
    <path
      d="M56.54 56.557c-3.062.128-6.005-2.742-5.936-5.81.03-1.509-2.074-1.785-2.35-.266-.564 3.147-1.255 6.382-3.151 9.006a1.348 1.348 0 0 0-.484.63c-.682.928.365 1.964 1.294 1.747 2.814 1.134 6.153 2.653 6.864 5.869.325 1.47 2.518 1.193 2.34-.336a12.338 12.338 0 0 1 2.529-8.956c.681-.878-.148-1.923-1.116-1.884h.01ZM47.591 60c.928-1.499 1.57-3.146 2.054-4.852 1.155 1.736 2.953 3.028 5.017 3.521a14.977 14.977 0 0 0-1.728 4.715c-1.442-1.48-3.378-2.525-5.343-3.393V60Z"
      fill="#fff"
    />
  </svg>
);
export default SvgEmptyBlog;
