import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoSubscription = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M5.06659 9.99167L6.99992 8.525L8.92492 9.99167L8.19159 7.61667L10.1249 6.08333H7.75825L6.99992 3.74167L6.24159 6.08333H3.87492L5.79992 7.61667L5.06659 9.99167ZM13.6666 6.91667C13.6666 3.23333 10.6833 0.25 6.99992 0.25C3.31659 0.25 0.333252 3.23333 0.333252 6.91667C0.333252 8.60833 0.966585 10.1417 1.99992 11.3167V17.75L6.99992 16.0833L11.9999 17.75V11.3167C13.0333 10.1417 13.6666 8.60833 13.6666 6.91667ZM6.99992 1.91667C9.75825 1.91667 11.9999 4.15833 11.9999 6.91667C11.9999 9.675 9.75825 11.9167 6.99992 11.9167C4.24159 11.9167 1.99992 9.675 1.99992 6.91667C1.99992 4.15833 4.24159 1.91667 6.99992 1.91667ZM6.99992 14.4167L3.66659 15.2667V12.6833C4.64992 13.25 5.78325 13.5833 6.99992 13.5833C8.21659 13.5833 9.34992 13.25 10.3333 12.6833V15.2667L6.99992 14.4167Z"
      />
    </svg>
  );
};
export default SvgIcoSubscription;
