import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M15.8333 0.5H2.49992C1.57492 0.5 0.841585 1.24167 0.841585 2.16667L0.833252 12.1667C0.833252 13.0917 1.57492 13.8333 2.49992 13.8333H10.8333V12.1667H2.49992V7.16667H17.4999V2.16667C17.4999 1.24167 16.7583 0.5 15.8333 0.5ZM15.8333 3.83333H2.49992V2.16667H15.8333V3.83333ZM19.1666 11.3333V13H16.6666V15.5H14.9999V13H12.4999V11.3333H14.9999V8.83333H16.6666V11.3333H19.1666Z"
    />
  </svg>
);
export default SvgIcoPayment;
