import * as React from 'react';
const SvgIcoSearchTitle = (prop: { width: number; height: number }) => {
  const { width, height } = prop;
  return (
    <img
      src="/img/icons/logo.png"
      style={{ width: `${width}px`, height: `${height}px` }}
      alt="logo"
    />
  );
};
export default SvgIcoSearchTitle;
