import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M9.66675 0.5C4.69666 0.5 0.666748 4.52991 0.666748 9.5C0.666748 14.4701 4.69666 18.5 9.66675 18.5C14.6368 18.5 18.6667 14.4701 18.6667 9.5C18.6667 4.52991 14.6368 0.5 9.66675 0.5ZM13.554 6.56094L9.32322 12.427C9.26409 12.5095 9.18614 12.5768 9.09582 12.6232C9.00551 12.6696 8.90544 12.6938 8.80391 12.6938C8.70238 12.6938 8.60231 12.6696 8.512 12.6232C8.42169 12.5768 8.34374 12.5095 8.28461 12.427L5.77947 8.95558C5.70313 8.84911 5.77947 8.70045 5.91005 8.70045H6.85224C7.05715 8.70045 7.25202 8.79888 7.37255 8.96763L8.80291 10.9525L11.9609 6.57299C12.0815 6.40625 12.2743 6.3058 12.4813 6.3058H13.4234C13.554 6.3058 13.6304 6.45446 13.554 6.56094Z"
    />
  </svg>
);
export default SvgIcoCheck;
