import React, { useEffect } from 'react';
import { Grid, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useSetAtom } from 'jotai';
import { Link } from 'react-router-dom';

import { Icon } from '@components/util';
import { NavigationTypes } from '@src/types/types';
import utils from '@src/utils/utils';
import {
  navigationConfig,
  bottomNavigationConfig,
} from '@src/layouts/navigation/navigation';
import { themeMobileNavAtom } from '@src/store/theme';
import { useChannelTalk } from '@hooks/channelTalk/useChannelTalk';
import { useMainInfo } from '@hooks/main';
import Scrollbars from 'react-custom-scrollbars';

const { useBreakpoint } = Grid;

const setDefaultOpen = (key: string) => {
  const keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

interface SideNavContentProps {
  type?: string;
  routeInfo?: NavigationTypes;
  customRouteInfo?: NavigationTypes[];
  hideGroupTitle?: string;
}

const SideNavContent = ({
  type,
  routeInfo,
  customRouteInfo,
  hideGroupTitle,
}: SideNavContentProps) => {
  const setMobileNav = useSetAtom(themeMobileNavAtom);
  const isMobile = utils.isMobile();
  const [mainInfo, _] = useMainInfo();
  const channelTalk = useChannelTalk();
  useEffect(() => {
    // 닫힌 서브 메뉴 펼침 처리
    try {
      const itemsWithSubmenu = document.querySelectorAll(
        'div[aria-expanded="false"]',
      );
      const openTitles = navigationConfig.map((menu) => menu.title);
      itemsWithSubmenu.forEach((node) => {
        const title = (node as HTMLElement).textContent || '';
        if (openTitles.includes(title)) {
          (node as HTMLElement).click();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const closeMobileNav = () => {
    if (isMobile) {
      setMobileNav(false);
    }
  };

  const menuItems: ItemType[] = navigationConfig
    .filter((menu: NavigationTypes) => !menu.isHidden)
    .map((menu: NavigationTypes) => getMenuItem(menu, closeMobileNav));
  const bottomMenuItems: ItemType[] = bottomNavigationConfig
    .filter((menu: NavigationTypes) => !menu.isHidden)
    .map((menu: NavigationTypes) => getMenuItem(menu, closeMobileNav));

  const handleOnClickChannelTalk = () => {
    if (mainInfo.isVisibleChannelTalk) {
      channelTalk.hide();
    } else {
      channelTalk.show();
    }
  };

  const customMenuItems: ItemType[] =
    customRouteInfo?.map((menu: NavigationTypes) => {
      return {
        label: (
          <a
            href="!@"
            onClick={(e) => {
              e.preventDefault();

              handleOnClickChannelTalk();
            }}
          >
            <span>{menu.title}</span>
          </a>
        ),
        key: menu.key,
        icon: <Icon type={menu.icon} />,
      };
    }) || [];

  const topItems = [...menuItems, ...customMenuItems];
  const bottomItems = [...bottomMenuItems];

  return (
    <Scrollbars
      style={{
        height: '100%',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          height: 'calc(100% - 70px)',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowX: 'hidden',
        }}
      >
        <Menu
          mode="inline"
          style={{
            borderRight: 0,
          }}
          defaultSelectedKeys={[routeInfo?.key ?? '']}
          selectedKeys={[routeInfo?.key ?? '']}
          defaultOpenKeys={setDefaultOpen(routeInfo?.key ?? '')}
          className={hideGroupTitle ? 'hide-group-title' : ''}
          items={topItems}
        />
        <div style={{ position: 'relative', height: '180px' }}>
          <Menu
            mode="inline"
            style={{
              borderRight: 0,
            }}
            defaultSelectedKeys={[routeInfo?.key ?? '']}
            selectedKeys={[routeInfo?.key ?? '']}
            defaultOpenKeys={setDefaultOpen(routeInfo?.key ?? '')}
            className={hideGroupTitle ? 'hide-group-title' : ''}
            items={bottomItems}
          />
        </div>
      </div>
    </Scrollbars>
  );
};

const getMenuItem = (
  menu: NavigationTypes,
  closeMobileNav?: () => void,
): ItemType => {
  if (!!menu?.submenu && menu?.submenu.length > 0) {
    const _menu: ItemType = {
      label: (
        <>
          <span>{menu.title}</span>
          {(menu?.submenu.length ?? 0) <= 0 ? (
            <Link onClick={closeMobileNav} to={menu.path} />
          ) : null}
        </>
      ),
      key: menu.key,
      icon: menu.icon ? <Icon type={menu.icon} /> : null,
      children: menu?.submenu.map((subMenuSecond: NavigationTypes) => {
        return getMenuItem(subMenuSecond, closeMobileNav);
      }),
      style: menu.isBottomMenu ? { width: '100%' } : {},
    };
    return _menu;
  } else {
    const _menu: ItemType = {
      label: (
        <>
          <span>{menu.title}</span>
          {(menu?.submenu.length ?? 0) <= 0 ? (
            <Link onClick={closeMobileNav} to={menu.path} />
          ) : null}
        </>
      ),
      key: menu.key,
      icon: menu.icon ? <Icon type={menu.icon} /> : null,
      style: menu.isBottomMenu
        ? { position: 'absolute', top: '600px', width: '100%' }
        : {},
    };
    return _menu;
  }
};

export const MenuContent = (props: SideNavContentProps) => {
  return <SideNavContent {...props} />;
};
