import React from 'react';
import { Button, Drawer, Menu, Modal } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { Scrollbars } from 'react-custom-scrollbars';
import { ArrowLeftOutlined, BulbFilled, BulbOutlined } from '@ant-design/icons';

import { Logo, MenuContent } from '@components/layout';
import { Flex } from '@components/shared';
import { NavigationTypes } from '@src/types/types';
import utils from '@src/utils/utils';
import { DARK_THEME } from '@src/constants/constant';
import { themeMobileNavAtom, themeCurrentThemeAtom } from '@src/store/theme';
import useLogout from '@hooks/auth/useLogout';

const { confirm } = Modal;

interface MobileNavProps {
  visible?: boolean;
  hideGroupTitle?: string;
  customRouteInfo?: NavigationTypes[];
  routeInfo?: NavigationTypes;
}

export const MobileNav = ({
  routeInfo,
  customRouteInfo,
  hideGroupTitle,
}: MobileNavProps) => {
  const [mobileNav, setMobileNav] = useAtom(themeMobileNavAtom);
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);
  const logout = useLogout();

  const onClose = () => {
    setMobileNav(false);
  };

  const mode = () => {
    return utils.getColorContrast(
      currentTheme === DARK_THEME ? '#00000' : '#ffffff',
    );
  };

  const navMode = mode();

  const handleOnClick = async () => {
    confirm({
      title: '안내',
      content: '로그아웃 하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        logout();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleClickSetTheme = () => {
    if (currentTheme === 'dark') {
      setCurrentTheme('light');
    } else {
      setCurrentTheme('dark');
    }
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={mobileNav}
      bodyStyle={{ padding: 5 }}
      width={mobileNav ? 320 : 0}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo={true} logoType={navMode} />
          <div aria-hidden="true" className="nav-close" onClick={onClose}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent
              type={'mobile'}
              routeInfo={routeInfo}
              customRouteInfo={customRouteInfo}
              hideGroupTitle={hideGroupTitle}
            />
            <div className={'bottom-button-wrap'}>
              <div className={'mr-4'}>
                <Button onClick={handleOnClick}>로그아웃</Button>
              </div>

              <div className={'mr-4'}>
                <Button
                  onClick={handleClickSetTheme}
                  icon={
                    currentTheme === 'light' ? (
                      <BulbOutlined className="nav-icon mr-0" />
                    ) : (
                      <BulbFilled className="nav-icon mr-0" />
                    )
                  }
                ></Button>
              </div>
            </div>
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};
