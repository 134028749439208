import { KAKAO_REDIRECT_URL } from '@src/constants/constant';
import { useCallback } from 'react';

export const useKakaoLogin = () => {
  const func = useCallback(() => {
    window.Kakao.Auth.authorize({
      redirectUri: KAKAO_REDIRECT_URL,
    });
  }, []);

  return func;
};

export const useGoogleLogin = () => {
  const func = useCallback(() => {
    //TODO: google login change
    window.Kakao.Auth.authorize({
      redirectUri: KAKAO_REDIRECT_URL,
    });
  }, []);

  return func;
};
