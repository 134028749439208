import React, { ReactElement, useRef } from 'react';
import { Button, Input, InputRef, Typography } from 'antd';
import { GlobalOutlined, SearchOutlined } from '@ant-design/icons';
import utils from '@src/utils/utils';

const { Title } = Typography;

interface ViewEmptyProps {
  setSearchValue?: (value: string) => void;
  inputPlaceholder?: string;
  title?: string | ReactElement;
  content?: string | ReactElement;
  icon?: string | ReactElement;
  onClickAddButton?: (value: string) => void;
  isInputComponent?: boolean;
}

const ViewEmpty = ({
  setSearchValue,
  inputPlaceholder,
  title,
  content,
  icon,
  onClickAddButton,
  isInputComponent = true,
}: ViewEmptyProps) => {
  const inputRef = useRef<InputRef>(null);
  const isMobile = utils.isMobile();
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!inputRef.current) {
      return;
    }

    if (inputRef.current.input?.value) {
      if (!!onClickAddButton) {
        onClickAddButton(inputRef.current.input?.value);
      } else {
        setSearchValue && setSearchValue(inputRef.current.input?.value);
      }
    }
  };

  return (
    <div className={'view-empty'}>
      {isInputComponent === true && (
        <Input
          className={'search-input'}
          ref={inputRef}
          suffix={
            <Button
              onClick={handleOnClick}
              type="text"
              icon={<SearchOutlined style={{ fontSize: '20px' }} />}
            ></Button>
          }
          placeholder={inputPlaceholder ?? '예시)  www.sample.com'}
        />
      )}

      <div className={`title ${isInputComponent === false ? 'no-input' : ''}`}>
        {' '}
        {title}
      </div>
      <div className={'content'}>{content}</div>

      <div className={'text-align-center image-wrap'}>
        {typeof icon === 'string' ? (
          <img
            className={`img-fluid ${isMobile ? '' : 'h-100'}`}
            src={icon}
            alt=""
          />
        ) : (
          icon && icon
        )}
      </div>
    </div>
  );
};

export default ViewEmpty;
