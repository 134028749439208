import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoStoreFront = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m16.423 6.668-.787-3.278a1.473 1.473 0 0 0-1.433-1.14H3.786c-.675 0-1.268.473-1.425 1.14l-.788 3.278a2.531 2.531 0 0 0 .465 2.16c.06.082.143.142.21.217v5.205c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V9.045c.068-.068.15-.135.21-.21.48-.615.653-1.403.465-2.168Zm-2.242-2.925.787 3.277c.075.315.008.63-.187.878a.88.88 0 0 1-.705.352c-.458 0-.855-.367-.908-.855l-.435-3.645 1.448-.007Zm-4.433.007h1.47l.405 3.39c.038.293-.052.585-.247.803-.165.194-.405.307-.713.307-.502 0-.915-.442-.915-.982V3.75ZM6.366 7.14l.412-3.39h1.47v3.518c0 .54-.412.982-.967.982a.902.902 0 0 1-.668-.308 1.065 1.065 0 0 1-.247-.802Zm-3.338-.12.758-3.27h1.477l-.435 3.645c-.06.488-.45.855-.907.855a.863.863 0 0 1-.698-.353.992.992 0 0 1-.195-.877Zm.72 7.23V9.727c.06.008.113.023.173.023.652 0 1.245-.27 1.68-.713.45.45 1.05.713 1.732.713.653 0 1.238-.27 1.673-.697a2.458 2.458 0 0 0 1.717.697c.63 0 1.23-.262 1.68-.713a2.347 2.347 0 0 0 1.68.713c.06 0 .113-.015.173-.023v4.523H3.748Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoStoreFront;
