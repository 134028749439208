import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoTickSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.125 32.083h8.75c7.292 0 10.208-2.916 10.208-10.208v-8.75c0-7.292-2.916-10.208-10.208-10.208h-8.75c-7.292 0-10.208 2.916-10.208 10.208v8.75c0 7.292 2.916 10.208 10.208 10.208Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m11.302 17.5 4.127 4.127 8.269-8.254"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoTickSquare;
