import React, { ReactElement, useRef, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  InputRef,
  Modal,
  Row,
  Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { LoadingFull } from '@components/shared';
import KakaoLogin from '@views/main/KakaoLogin';

const { Title } = Typography;

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

interface LoginFormProps {
  showLoading?: boolean;
  extra?: string | ReactElement;
  loading?: boolean;
  showMessage?: string;
  message?: string;
}

const Main = (props: LoginFormProps) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const inputRef = useRef<InputRef>(null);

  const handleOnClickInput = () => {
    const value = inputRef.current?.input?.value ?? '';
    navigate('/main/survey', { state: { keyword: value } });
  };

  const handleOnClickFreeStart = () => {
    navigate('/main/survey');
  };

  return (
    <div className={'main-container'}>
      <div className="h-100" style={backgroundStyle}>
        <div className="container d-flex flex-column justify-content-center h-100">
          <Button
            className={'top-button strong text-white'}
            type={'primary'}
            onClick={handleOnClickFreeStart}
          >
            무료로 시작하기
          </Button>
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={7}>
              <div className="my-4">
                <div className="text-center">
                  <Title
                    className={'strong mb-0 text-align-left text-white'}
                    level={3}
                  >
                    우리 회사의 진짜
                    <br /> 마케팅 지표를 확인하세요
                  </Title>
                  <p className={' strong mt-0 text-align-left text-white'}>
                    마켓닥터가 마케팅을 진단하고 치료해드립니다.
                  </p>

                  <Input
                    className={'mt-4'}
                    ref={inputRef}
                    suffix={
                      <Button
                        type={'text'}
                        onClick={handleOnClickInput}
                        icon={<SearchOutlined />}
                      />
                    }
                    placeholder={'서비스 or 제품 or 대표키워드를 입력해주세요'}
                  />
                </div>
                <Row justify="center" style={{ marginTop: '200px' }}>
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Divider>
                      <span className="font-size-base font-weight-normal text-white">
                        or connect with
                      </span>
                    </Divider>
                    <KakaoLogin />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {isLoading ? <LoadingFull /> : null}
    </div>
  );
};

export default Main;
