import React, { ReactNode, Suspense, SuspenseProps } from 'react';
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithRender,
} from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';

type ExceptFallbackErrorBoundaryAttributes = Omit<
  ErrorBoundaryPropsWithRender,
  'fallbackRender' | 'fallback' | 'FallbackComponent'
>;

type AsyncBoundaryProps = {
  children: ReactNode;
  ErrorFallback: ErrorBoundaryPropsWithRender['fallbackRender'];
  SuspenseFallback: SuspenseProps['fallback'];
} & ExceptFallbackErrorBoundaryAttributes;

function AsyncBoundary({
  children,
  ErrorFallback,
  SuspenseFallback,
  ...restErrorBoundaryAttributes
}: AsyncBoundaryProps) {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={() => {
        reset();
      }}
      fallbackRender={ErrorFallback}
      {...restErrorBoundaryAttributes}
    >
      <Suspense fallback={SuspenseFallback}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export default AsyncBoundary;
