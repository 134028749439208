import React, { ReactElement } from 'react';
import { Button, Typography } from 'antd';
import { IcoSearchTitle } from '@src/assets/svg';

const { Title } = Typography;

const titleIcon = <IcoSearchTitle width={32} height={32} />;

interface SearchTopProps {
  title?: string;
  icon?: ReactElement;
  className?: string;
  onClickDeleteButton?: () => void;
}

const SearchTop = ({
  title,
  icon = titleIcon,
  className,
  onClickDeleteButton,
}: SearchTopProps) => {
  const handleOnClickTitleClose = () => {
    onClickDeleteButton && onClickDeleteButton();
  };

  return (
    <div className={`search-top d-flex align-items-center ${className}`}>
      {icon && icon}

      <Title level={4} className={'m-0 ml-2'}>
        {title}
      </Title>
      <Button
        type="text"
        shape="round"
        style={{ padding: '4px' }}
        icon={<img src={'/img/svg/ico-close-circle.svg'} alt="" />}
        size={'small'}
        onClick={handleOnClickTitleClose}
      />
    </div>
  );
};

export default SearchTop;
