import React from 'react';
import { Loading } from '@components/shared/Loading';

export const LoadingFull = () => {
  return (
    <div
      style={{
        position: 'fixed',
        inset: '0px',
        zIndex: 1000,
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
      }}
      className={'d-flex flex-column justify-content-center'}
    >
      <Loading cover={'page'} />
    </div>
  );
};
