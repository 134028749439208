import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoHashtag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5 33h9C30 33 33 30 33 22.5v-9C33 6 30 3 22.5 3h-9C6 3 3 6 3 13.5v9C3 30 6 33 13.5 33Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 25.5h6c2.475 0 4.5-2.025 4.5-4.5v-6c0-2.475-2.025-4.5-4.5-4.5h-6a4.513 4.513 0 0 0-4.5 4.5v6c0 2.475 2.025 4.5 4.5 4.5ZM18 10.5v15M10.5 18h15"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoHashtag;
