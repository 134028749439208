import React, { ReactElement } from 'react';
import { Card, Progress, Typography } from 'antd';
import utils from '@src/utils/utils';

const { Title } = Typography;

interface GoalWidgetProps {
  title?: string | ReactElement;
  value?: number;
  size?: number;
  strokeWidth?: number;
  subtitle?: string;
  extra?: string | ReactElement;
  className?: string;
}

export const GoalWidget = ({
  title,
  value,
  size = 270,
  subtitle,
  strokeWidth = 4,
  extra,
  className,
}: GoalWidgetProps) => {
  return (
    <Card
      bordered={false}
      className={`goal-widget ${className ? className : ''}`}
    >
      <div className="text-center">
        {title && <div className="title">{title}</div>}
        <div className="point">{value?.toLocaleString()}점</div>
        <Progress percent={value} showInfo={false} />

        <div className={'desc'}>{subtitle}</div>
        {extra}
      </div>
    </Card>
  );
};

export default GoalWidget;
