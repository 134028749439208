import React from 'react';
import { useLocation } from 'react-router-dom';
import { ABOUT_US_URL } from '@src/constants/constant';

const AboutUs = () => {
  const location = useLocation();

  if (location.state != null) {
    const { url } = location.state as { url: string };
    if (url) {
      window.location.href = url;
    }
  }

  window.location.href = ABOUT_US_URL;

  return null;
};

export default AboutUs;
