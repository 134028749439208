import React from 'react';
import MainRoutes from '@src/layouts/routes/MainRoutes';

export const MainLayout = () => {
  console.log('MainLayout');
  return (
    <div className="auth-container">
      <MainRoutes />
    </div>
  );
};

export default MainLayout;
