import * as React from 'react';
import { SVGProps } from 'react';
const SvgAddCircleOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.792 16.042h-4.584v9.166h-9.166v4.584h9.166v9.166h4.584v-9.166h9.166v-4.584h-9.166v-9.166ZM27.5 4.583C14.85 4.583 4.584 14.85 4.584 27.5c0 12.65 10.266 22.917 22.916 22.917S50.417 40.15 50.417 27.5c0-12.65-10.267-22.917-22.917-22.917Zm0 41.25c-10.106 0-18.333-8.227-18.333-18.333 0-10.106 8.227-18.333 18.333-18.333 10.106 0 18.334 8.227 18.334 18.333 0 10.106-8.228 18.333-18.334 18.333Z"
      fill="#6D6969"
    />
  </svg>
);
export default SvgAddCircleOutline;
