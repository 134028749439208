import React from 'react';
import { useAtomValue } from 'jotai';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';

import Views from '@views/Views';

import { themeCurrentThemeAtom } from '@src/store/theme';
import { OpenAPI } from '@src/api';
import {
  CLIENT_ID,
  CLIENT_SECRET,
  KAKAO_JAVASCRIPT_KEY,
  KEYWORD_API_URL,
} from '@src/constants/constant';
import { ConfigProvider } from 'antd';

OpenAPI.BASE = KEYWORD_API_URL ?? '/';
OpenAPI.HEADERS = {
  'client-id': CLIENT_ID,
  'client-secret': CLIENT_SECRET,
};

declare global {
  interface Window {
    Kakao: any;
    ChannelIO: any;
    ChannelIOInitialized: boolean;
    ChannelIOVisible: boolean;
  }
}

const themes = {
  dark: `/css/dark-theme.css`,
  light: `/css/light-theme.css`,
};

function App() {
  const currentTheme = useAtomValue(themeCurrentThemeAtom);

  if (!window.Kakao.isInitialized()) {
    // JavaScript key를 인자로 주고 SDK 초기화
    window.Kakao.init(KAKAO_JAVASCRIPT_KEY);
    // SDK 초기화 여부를 확인하자.
    console.log(window.Kakao.isInitialized());
  }

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <ConfigProvider>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Views />} />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </ThemeSwitcherProvider>
    </div>
  );
}

export default App;
