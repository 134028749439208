import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoChartSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.535 20.442h-4.858c-1.155 0-2.09.935-2.09 2.09v9.386h6.948V20.442v0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.396 12.1h-2.787a2.09 2.09 0 0 0-2.09 2.09V31.9h6.948V14.19c0-1.155-.916-2.09-2.071-2.09ZM30.338 23.558H25.48V31.9h6.948v-6.252c-.018-1.155-.953-2.09-2.09-2.09Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 40.333h11c9.167 0 12.833-3.666 12.833-12.833v-11c0-9.167-3.666-12.833-12.833-12.833h-11C7.333 3.667 3.667 7.333 3.667 16.5v11c0 9.167 3.666 12.833 12.833 12.833Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoChartSquare;
