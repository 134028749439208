import React, { useEffect } from 'react';
import { Grid, Layout } from 'antd';
import { useAtomValue } from 'jotai';

import {
  APP_PREFIX_PATH,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from '@src/constants/constant';
import utils from '@src/utils/utils';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useLocation } from 'react-router-dom';

import { Loading } from '@components/shared';
import {
  Footer,
  HeaderNav,
  MobileNav,
  PageHeader,
  SideNav,
} from '@components/layout';
import AppRoutes from '@src/layouts/routes/AppRoutes';
import navigationConfig, {
  bottomNavigationConfig,
} from '@src/layouts/navigation/navigation';
import { NavigationTypes } from '@src/types/types';

import { themeNavCollapsedAtom } from '@src/store/theme';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useChannelTalk } from '@hooks/channelTalk/useChannelTalk';

const subHeaderUrl = ['/analysis'];
export const AppLayout = () => {
  const navCollapsed = useAtomValue(themeNavCollapsedAtom);
  const { pathname } = useLocation();
  const channelTalk = useChannelTalk();
  const currentRouteInfo = utils.getRouteInfo(
    [...navigationConfig, ...bottomNavigationConfig],
    pathname,
  ) as NavigationTypes;
  const isMobile = utils.isMobile();
  const getLayoutGutter = () => {
    if (isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();
  const customRouteInfo: NavigationTypes[] = [
    {
      key: '',
      path: '',
      title: '무료상담',
      icon: ExclamationCircleOutlined,
      breadcrumb: true,
      submenu: [],
    },
  ];

  const isSubHeaderActive = () => {
    const path = location.pathname;
    let flag = true;
    subHeaderUrl?.map((item) => {
      if (path.includes(item) == false) {
        flag = false;
        return;
      }
    });

    return flag;
  };

  useEffect(() => {
    channelTalk.boot();

    return () => {
      channelTalk.shutdown();
    };
  }, []);

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Layout className="app-container">
        {!isMobile ? (
          <SideNav
            routeInfo={currentRouteInfo}
            customRouteInfo={customRouteInfo}
          />
        ) : null}
        <Layout
          className="app-layout"
          style={{ paddingLeft: getLayoutGutter() }}
        >
          <div className={'app-content-header'}>
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
          </div>

          <div
            className={`app-sub-content ${
              isSubHeaderActive() ? '' : 'no-sub-header'
            }`}
          >
            <AppRoutes />
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && (
        <MobileNav
          routeInfo={currentRouteInfo}
          customRouteInfo={customRouteInfo}
        />
      )}
    </Layout>
  );
};

export default React.memo(AppLayout);
