import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import MainLayout from '@src/layouts/MainLayout';
import AppLayout from '@src/layouts/AppLayout';

import { APP_PREFIX_PATH, MAIN_PATH } from '@src/constants/constant';
import { useApiError } from '@hooks/api/error';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Loading } from '@components/shared';
import { useAtom } from 'jotai';
import { authAtom } from '@src/store/auth';

export const Views = () => {
  const { handleError } = useApiError();
  const [authInfo, setAuthInfo] = useAtom(authAtom);

  const queryOnError = async (error: unknown) => {
    const ret = handleError(error as Error);

    const errorsKeys = queryClient
      .getQueryCache()
      .getAll() // react-query의 query cache에서
      .filter((q) => q.state.status === 'error') // error를 캐싱한 것만 골라
      .map((e) => e.queryKey);

    await queryClient.invalidateQueries(errorsKeys);

    if (ret) {
      throw error;
    }
  };

  // Create a client
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            staleTime: 5000,
            cacheTime: Infinity,
            suspense: true,
            useErrorBoundary: true,
          },
        },
        queryCache: new QueryCache({
          onError: queryOnError,
        }),
        //mutationCache: new MutationCache({
        //  onError: queryOnError,
        //}),
      }),
    [],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Routes>
          <Route path="/" element={<Navigate replace to={APP_PREFIX_PATH} />} />
          <Route
            path={`${APP_PREFIX_PATH}/*`}
            element={
              authInfo?.accessToken ? (
                <AppLayout />
              ) : (
                <Navigate replace to={MAIN_PATH} />
              )
            }
          />
          <Route path={`/*`} element={<MainLayout />} />
        </Routes>
        {process.env.NODE_ENV !== 'production' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </>
    </QueryClientProvider>
  );
};

export default Views;
