import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    width="1em"
    height="1em"
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
    />
  </svg>
);
export default SvgIcoTrash;
