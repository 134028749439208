import React from 'react';
import { AppBreadcrumb } from '@components/layout';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { APP_PREFIX_PATH } from '@src/constants/constant';
import { Button, Grid, Tabs } from 'antd';
import utils from '@src/utils/utils';
import Scrollbars from 'react-custom-scrollbars';

interface PageHeaderProps {
  title: string;
  display: boolean;
}

export const PageHeader = ({ title, display }: PageHeaderProps) => {
  const isMobile = utils.isMobile();

  return isMobile ? (
    <MobileAnalysisHeader />
  ) : display ? (
    <AnalysisHeader title={title} />
  ) : null;
};
const AnalysisHeader = ({ title }: { title: string }) => {
  const pathname = window.location.pathname;

  const isHide = pathname.includes('/analysis/main') ? true : false;
  return (
    <div className={`app-page-header`}>
      <div className="mb-0 mr-3 text">{title ? title : 'home'}</div>
      {isHide === false && <AppBreadcrumb />}
    </div>
  );
};

const headerLabels = [
  { label: '종합분석', path: '/analysis/main' },
  { label: '성장 추이', path: '/analysis/growth-trend' },
  { label: '키워드 검색량', path: '/analysis/naver-keyword-search-volume' },
  // { label: '홈페이지', path: '/analysis/homepage' },
  { label: '인스타그램', path: '/analysis/sns-channel/instargram' },
  { label: '블로그', path: '/analysis/sns-channel/blog' },
  { label: '스마트 스토어 분석', path: '/analysis/smart-store' },
  { label: '스마트 플레이스 분석', path: '/analysis/smart-place' },
];
const MobileAnalysisHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path: string) => {
    return location.pathname.includes(APP_PREFIX_PATH + path);
  };

  const handleOnChangeTabs = (activeKey: string) => {
    if (!activeKey) {
      return;
    }
    navigate(activeKey);
  };

  const handleOnTabClick = (
    activeKey: string,
    e: React.KeyboardEvent | React.MouseEvent,
  ) => {
    console.log(e);
    const element: HTMLDivElement = e.target as HTMLDivElement;
    const parentElement = element?.offsetParent as HTMLDivElement;
    const scrollWrap = parentElement?.offsetParent as HTMLDivElement;

    const screenWidth = window.screen.width;
    const scrollWrapClientRect = scrollWrap?.getBoundingClientRect();
    const parentElementRect = parentElement?.getBoundingClientRect();
    const marginRight = 12;
    const spareWidth = 50;
    let moveX =
      parentElementRect.right -
      scrollWrapClientRect.x -
      screenWidth +
      marginRight +
      spareWidth;

    if (moveX > 0) {
      const maxSize = scrollWrapClientRect.width - screenWidth;

      if (moveX > maxSize) {
        moveX = maxSize;
      }
    } else {
      moveX = 0;
    }

    scrollWrap.style.transform = `translate(-${moveX}px, 0px)`;
  };

  if (isActive('/analysis') === false) {
    return null;
  }

  return (
    <div className={`app-page-m-header`}>
      <Tabs
        defaultActiveKey={window.location.pathname}
        tabPosition={'top'}
        hideAdd={true}
        size={'small'}
        items={headerLabels.map((info) => {
          return {
            label: info.label,
            key: `${APP_PREFIX_PATH}${info.path}`,
          };
        })}
        onChange={handleOnChangeTabs}
        onTabClick={handleOnTabClick}
      />
    </div>
  );
};
