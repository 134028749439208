import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.75 3.773h-5.363c-1.15 0-2.275.33-3.243.954L12 5.461l-1.144-.734a5.982 5.982 0 0 0-3.244-.954H2.25a.75.75 0 0 0-.75.75v13.313c0 .415.335.75.75.75h5.362c1.151 0 2.276.33 3.244.954l1.04.67c.031.019.066.03.102.03.035 0 .07-.009.1-.03l1.041-.67a6.009 6.009 0 0 1 3.248-.954h5.363a.75.75 0 0 0 .75-.75V4.523a.75.75 0 0 0-.75-.75Zm-12.281 9.2c0 .096-.075.175-.167.175H4.948c-.092 0-.167-.08-.167-.175v-1.055c0-.096.075-.176.167-.176H9.3c.091 0 .166.08.166.176v1.055h.003Zm0-3.282c0 .096-.075.176-.167.176H4.948c-.092 0-.167-.08-.167-.176V8.637c0-.096.075-.176.167-.176H9.3c.091 0 .166.08.166.176V9.69h.003Zm9.75 3.282c0 .096-.075.175-.167.175h-4.354c-.092 0-.167-.08-.167-.175v-1.055c0-.096.075-.176.167-.176h4.352c.091 0 .166.08.166.176v1.055h.003Zm0-3.282c0 .096-.075.176-.167.176h-4.354c-.092 0-.167-.08-.167-.176V8.637c0-.096.075-.176.167-.176h4.352c.091 0 .166.08.166.176V9.69h.003Z"
      fill="#89949F"
    />
  </svg>
);
export default SvgIcoNews;
