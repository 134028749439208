import React, { ChangeEventHandler, Ref, useRef, useState } from 'react';
import {
  Button,
  Carousel,
  Col,
  Input,
  InputRef,
  Modal,
  Row,
  Typography,
} from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { useLocation, useNavigate } from 'react-router-dom';

import { MAIN_PATH } from '@src/constants/constant';
import { Loading } from '@components/shared';

const { Title } = Typography;
const { confirm } = Modal;

interface SurveyProps {
  message?: string;
}

const Survey = (props: SurveyProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const carouselRef = useRef<CarouselRef>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState<string>(() => {
    return location.state?.keyword ?? '';
  });
  const [homepage, setHomepage] = useState<string>();
  const [middleNumber, setMiddleNumber] = useState<string>();
  const [lastNumber, setLastNumber] = useState<string>();

  const inputRefAppName = useRef<InputRef>(null);
  const inputRefMiddleNumber = useRef<InputRef>(null);
  const inputRefLastNumber = useRef<InputRef>(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    switch (name) {
      case 'appName':
        setKeyword(value);
        break;
      case 'homepage':
        setHomepage(value);
        break;
      case 'middleNumber':
        setMiddleNumber(value);
        break;
      case 'lastNumber':
        setLastNumber(value);
        break;
    }
  };

  const handleOnClickNext = () => {
    if (currentIndex === 3) {
      navigate(MAIN_PATH);
    } else {
      if (currentIndex === 0) {
        if (!keyword || keyword?.length <= 0) {
          confirm({
            title: '안내',
            content: '서비스 명 or 제품명은 필수항목 입니다.',
            okText: '확인',
            okCancel: false,
            onOk() {
              inputRefAppName.current?.focus();
            },
          });

          return;
        }
      } else if (currentIndex === 2) {
        let focusFunc: any = null;
        if (!middleNumber || middleNumber?.length <= 0) {
          focusFunc = inputRefMiddleNumber.current?.focus;
        } else if (!lastNumber || lastNumber?.length <= 0) {
          focusFunc = inputRefLastNumber.current?.focus;
        }

        if (!!focusFunc) {
          confirm({
            title: '안내',
            content: '전화번호는 필수항목 입니다.',
            okText: '확인',
            okCancel: false,
            onOk() {
              focusFunc && focusFunc();
            },
          });

          return;
        }

        //todo: 전화번호 체크

        return;
      }

      carouselRef.current && carouselRef.current.next();
    }
  };

  const handleOnClickPrev = () => {
    carouselRef.current && carouselRef.current.prev();
  };

  const handleAfterChange = (index: number) => {
    setCurrentIndex(index);
  };

  const isPrevBtnHide = currentIndex > 0 && currentIndex < 3 ? true : false;
  console.log(isPrevBtnHide);
  return (
    <>
      <div className={'main-container'}>
        <div className="h-100 survey">
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={7}>
                <div className="my-4 p-3">
                  <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Carousel
                        ref={carouselRef}
                        dots={false}
                        infinite={false}
                        touchMove={false}
                        afterChange={handleAfterChange}
                      >
                        <SurveyAppName
                          value={keyword}
                          onChange={handleOnChange}
                          inputRef={inputRefAppName}
                        />
                        <SurveyHomepage
                          value={homepage}
                          onChange={handleOnChange}
                        />
                        <SurveyPhoneNumber
                          value={middleNumber}
                          value2={lastNumber}
                          inputRef={inputRefMiddleNumber}
                          inputRef2={inputRefLastNumber}
                          onChange={handleOnChange}
                        />
                        <SurveyFinal />
                      </Carousel>
                      <div
                        className={`d-flex ${
                          isPrevBtnHide
                            ? 'justify-content-between'
                            : 'justify-content-end'
                        }`}
                      >
                        {isPrevBtnHide && (
                          <Button
                            className={'next-button strong text-white'}
                            type="primary"
                            shape="round"
                            onClick={handleOnClickPrev}
                          >
                            이전
                          </Button>
                        )}

                        <Button
                          className={'next-button strong text-white'}
                          type="primary"
                          shape="round"
                          onClick={handleOnClickNext}
                        >
                          {currentIndex === 3 ? '확인' : '다음'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {isLoading && <Loading cover="content" />}
    </>
  );
};

interface props {
  inputRef?: Ref<InputRef>;
  inputRef2?: Ref<InputRef>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  value2?: string;
}

type OnChange = ChangeEventHandler<HTMLInputElement>;

const SurveyAppName = ({ onChange, value, inputRef }: props) => {
  return (
    <div className="text-center">
      <Title className={'strong mb-0 text-align-left'} level={5}>
        서비스명 or 제품명 중 하나를 선택해 입력해주세요.
      </Title>
      <Input
        ref={inputRef}
        name={'appName'}
        className={'mt-4'}
        value={value}
        onChange={onChange}
      />

      <p className={' strong mt-2 text-align-left'}>* 필수 입력사항입니다.</p>
    </div>
  );
};

const SurveyHomepage = ({ onChange, value }: props) => {
  return (
    <div className="text-center">
      <Title className={'strong mb-0 text-align-left'} level={5}>
        홈페이지 주소를 입력해주세요
      </Title>
      <Title className={'strong mb-0 text-align-left'} level={5}>
        없을 경우,
        <br />
        입력 없이 다음 버튼을 눌러주세요
      </Title>
      <Input
        className={'mt-4'}
        placeholder={'예시) www.homepage.com'}
        name={'homepage'}
        value={value}
        onChange={onChange}
      />
      <p className={' strong mt-2 text-align-left'}>* 선택 입력사항입니다.</p>
    </div>
  );
};

const SurveyPhoneNumber = ({
  onChange,
  value,
  value2,
  inputRef,
  inputRef2,
}: props) => {
  const handleOnInput = (e: React.MouseEvent<HTMLInputElement>) => {
    console.log('teswt');
    const value = e.currentTarget.value;
    e.currentTarget.value = value.replace(/[^0-9.]/g, '');
  };
  return (
    <div className="text-center">
      <Title className={'strong mb-0 text-align-left'} level={5}>
        결과를 드릴 수 있게
        <br />
        전화번호를 입력해주세요.
      </Title>
      <div className={'d-flex justify-content-between mt-4 align-items-center'}>
        <Title level={5} className={'m-0'}>
          010
        </Title>
        <Title level={5} className={'m-0 p-2'}>
          -
        </Title>
        <Input
          ref={inputRef}
          name={'middleNumber'}
          maxLength={4}
          value={value}
          onChange={onChange}
          onInput={handleOnInput}
        />
        <Title level={5} className={'m-0 p-2'}>
          -
        </Title>
        <Input
          ref={inputRef2}
          name={'lastNumber'}
          maxLength={4}
          value={value2}
          onChange={onChange}
          onInput={handleOnInput}
        />
      </div>
      <p className={' strong mt-2 text-align-left'}>* 필수 입력사항입니다.</p>
    </div>
  );
};

const SurveyFinal = () => {
  return (
    <div className="text-center">
      <Title className={'strong mb-5 text-align-left'} level={5}>
        지금부터
        <br />
        마켓탁터가 서비스를 분석하여
        <br />
        24시간 이내 카톡으로
        <br />
        결과를 알려드리겠습니다.
      </Title>
    </div>
  );
};

export default Survey;
