/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { useCallback } from 'react';
import { channelTalkConstant } from '@src/constants/channelTalkConstant';
import { useMainInfo } from '@hooks/main';

export const useChannelTalk = () => {
  const [_, setMainInfo] = useMainInfo();

  const boot = useCallback(() => {
    if (!window.ChannelIO) {
      let ch = function () {
        // @ts-ignore
        ch.c(arguments);
      };
      // @ts-ignore
      ch.q = [];
      // @ts-ignore
      ch.c = function (args) {
        // @ts-ignore
        ch.q.push(args);
      };
      window.ChannelIO = ch;

      window.ChannelIOInitialized = true;
      const mapScript = document.createElement('script');
      mapScript.src = `https://cdn.channel.io/plugin/ch-plugin-web.js`;

      document.body.appendChild(mapScript);
      mapScript.addEventListener('load', () => {
        window.ChannelIO('boot', channelTalkConstant);
        window.ChannelIO('onShow', function () {
          console.log('show');
          setMainInfo({
            isVisibleChannelTalk: true,
          });
        });
        window.ChannelIO('onHide', function () {
          console.log('hide');
          setMainInfo({
            isVisibleChannelTalk: false,
          });
        });
      });
    } else {
      window.ChannelIO('boot', channelTalkConstant);
    }
  }, [window.ChannelIO]);

  const shutdown = useCallback(() => {
    window.ChannelIO('shutdown');
  }, [window.ChannelIO]);

  const show = useCallback(() => {
    window.ChannelIO('show');
  }, [window.ChannelIO]);

  const hide = useCallback(() => {
    window.ChannelIO('hide');
  }, [window.ChannelIO]);

  return {
    boot: boot,
    shutdown: shutdown,
    show: show,
    hide: hide,
  };
};
