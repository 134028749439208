import React from 'react';
import { Button, Layout, Modal } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { Scrollbars } from 'react-custom-scrollbars';

import { MenuContent } from '@components/layout/MenuContent';
import { DARK_THEME, SIDE_NAV_WIDTH } from '@src/constants/constant';
import { NavigationTypes } from '@src/types/types';
import {
  themeCurrentThemeAtom,
  themeMobileNavAtom,
  themeNavCollapsedAtom,
} from '@src/store/theme';
import { BulbFilled, BulbOutlined } from '@ant-design/icons';
import useLogout from '@hooks/auth/useLogout';
import utils from '@src/utils/utils';

const { Sider } = Layout;
const { confirm } = Modal;

interface SideNavProps {
  hideGroupTitle?: string;
  customRouteInfo?: NavigationTypes[];
  routeInfo?: NavigationTypes;
}

export const SideNav = ({
  routeInfo,
  customRouteInfo,
  hideGroupTitle,
}: SideNavProps) => {
  const navCollapsed = useAtomValue(themeNavCollapsedAtom);
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);
  const logout = useLogout();

  const mode = () => {
    return utils.getColorContrast(
      currentTheme === DARK_THEME ? '#00000' : '#ffffff',
    );
  };

  const navMode = mode();

  const handleOnClick = async () => {
    confirm({
      title: '안내',
      content: '로그아웃 하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        logout();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleClickSetTheme = () => {
    if (currentTheme === 'dark') {
      setCurrentTheme('light');
    } else {
      setCurrentTheme('dark');
    }
  };

  return (
    <Sider
      className={`side-nav`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        <MenuContent
          routeInfo={routeInfo}
          customRouteInfo={customRouteInfo}
          hideGroupTitle={hideGroupTitle}
        />
        <div className={'bottom-button-wrap'}>
          <div className={'mr-4'}>
            <Button onClick={handleOnClick}>로그아웃</Button>
          </div>

          <div className={'mr-4'}>
            <Button
              onClick={handleClickSetTheme}
              icon={
                currentTheme === 'light' ? (
                  <BulbOutlined className="nav-icon mr-0" />
                ) : (
                  <BulbFilled className="nav-icon mr-0" />
                )
              }
            ></Button>
          </div>
        </div>
      </Scrollbars>
    </Sider>
  );
};
