import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoMd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.549 6.5 11.999 1 2.45 6.5v11L12 23l9.55-5.5v-11Zm-4.824.786H7.274v9.428h9.451V7.286Z"
      fill="#89949F"
    />
  </svg>
);
export default SvgIcoMd;
