import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoBlog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.304 3H7.696A4.696 4.696 0 0 0 3 7.696v8.608A4.696 4.696 0 0 0 7.696 21h8.608A4.696 4.696 0 0 0 21 16.304V7.696A4.696 4.696 0 0 0 16.304 3Z"
      fill="#4162D7"
    />
    <path
      d="M8 7h4.21c.728 0 1.347.104 1.858.31.515.203.902.493 1.16.87.261.374.392.816.392 1.327a2.11 2.11 0 0 1-.901 1.761 2.601 2.601 0 0 1-.983.421v.097a2.46 2.46 0 0 1 1.145.331c.348.203.622.481.82.836.2.355.299.762.299 1.222a2.58 2.58 0 0 1-.42 1.458c-.276.423-.674.757-1.194 1.001-.515.244-1.123.366-1.823.366H8V7Zm3.885 7.894c.307 0 .563-.042.766-.125a.948.948 0 0 0 .46-.352c.105-.156.157-.35.157-.58 0-.235-.054-.437-.163-.608a.923.923 0 0 0-.46-.386 1.857 1.857 0 0 0-.72-.139h-1.349v2.19h1.309Zm-.15-3.826c.24-.005.453-.046.638-.125.19-.082.337-.198.44-.345a.931.931 0 0 0 .156-.539.899.899 0 0 0-.155-.531.888.888 0 0 0-.42-.339 1.539 1.539 0 0 0-.618-.117h-1.2v1.996h1.16Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIcoBlog;
