import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const PageError = ({ fallbackProps }: { fallbackProps: FallbackProps }) => {
  const handleOnClick = () => {
    fallbackProps.resetErrorBoundary();
  };

  return (
    <div>
      <h1> 데이터를 불러오는데 실패하였습니다. </h1>
      <p> 에러가 지속되면 고객센터로 문의하세요. </p>
      <button onClick={handleOnClick}>다시시도</button>
    </div>
  );
};

export default PageError;
