import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoMenuBoard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m37.464 11.548-5.757 23.114a3.805 3.805 0 0 1-3.724 2.921H5.535c-2.58 0-4.425-2.528-3.656-5.005L9.071 9.48a3.845 3.845 0 0 1 3.656-2.716H33.74c1.622 0 2.972.99 3.536 2.357.324.735.393 1.572.188 2.426Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M27.333 37.583H35.5a3.785 3.785 0 0 0 3.776-4.066L37.583 10.25M16.537 10.9l1.776-7.38M27.982 10.916l1.606-7.414M13.154 20.5h13.667M11.446 27.333h13.667"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoMenuBoard;
