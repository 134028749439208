import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useAtomValue } from 'jotai';

import { Flex } from '@components/shared';
import { APP_HOME_PATH, LIGHT_THEME } from '@src/constants/constant';

import { themeCurrentThemeAtom } from '@src/store/theme';

const Error404 = () => {
  const currentTheme = useAtomValue(themeCurrentThemeAtom);

  return (
    <div className={`h-100 ${currentTheme === LIGHT_THEME ? 'bg-white' : ''}`}>
      <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
        <div></div>
        <div className="container">
          <Row align="middle">
            <Col xs={24} sm={24} md={8}>
              <h1 className="font-weight-bold mb-4 display-4">
                Page not found
              </h1>
              <p className="font-size-md mb-4">
                noticed you lost your way, no worries, we will help you to found
                the correct path.
              </p>
              <Link to={APP_HOME_PATH}>
                <Button type="primary" icon={<ArrowLeftOutlined />}>
                  Go back
                </Button>
              </Link>
            </Col>
            <Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
              <img
                className="img-fluid mt-md-0 mt-4"
                src="/img/others/img-20.png"
                alt=""
              />
            </Col>
          </Row>
        </div>
        <Flex mobileFlex={false} justifyContent="between">
          <div></div>
        </Flex>
      </div>
    </div>
  );
};

export default Error404;
