import { useMutation } from '@tanstack/react-query';
import { MutationOptionsType } from '@src/types';
import { ApiService } from '@src/api/services/ApiService';
import { SignInViaKakaoCommand } from '@src/api';
import { ApiResponse } from '@src/api/custom/models/ApiResponse';

interface Props {
  options?: MutationOptionsType<ApiResponse, SignInViaKakaoCommand>;
}

const fetchKakaoLogin = async (tokenRequest: SignInViaKakaoCommand) => {
  const response = await ApiService.signInViaKakao({
    requestBody: tokenRequest,
  });
  return response;
};

// 상품문의 등록
export const useMutationKakaoLogin = ({ options }: Props) => {
  return useMutation(fetchKakaoLogin, {
    onSuccess: (data: any) => {
      console.log('onSuccess');
    },
    onError: (error: any) => {
      console.log('onError');
    },
    onSettled: () => {
      console.log('onSettled');
    },
    ...options,
  });
};
